
import { computed, defineComponent } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { useMeta } from "vue-meta"

export default defineComponent({
    name: 'GoogleRecaptcha',
    components: {
        VueRecaptcha
    },
    props: {
        language: {
            type: String,
            default: () => 'en',
            required: false
        },
        executeTrigger: {
            type: Number,
            default: () => 0,
            required: false
        },
        resetTrigger: {
            type: Number,
            default: () => 0,
            required: false
        }
    },
    setup(props, context) {

        const siteKey = computed(() => {
            return '6LfK6X4eAAAAAH-3LkXzU0Li2Hekrfeoe8GcE3fH';
        });

        const rendered = (id: string) => {
            // console.log('Google reCaptcha rendered id:')
            // console.log(id)
            context.emit('onCaptchaRendered')
        }

        const success = (token: string) => {
            // console.log('Google reCaptcha response:')
            // console.log(token)
            context.emit('onCaptchaSuccess', token)
        }

        const expired = () => {
            // console.log('Google reCaptcha expired')
            context.emit('onCaptchaExpired')
        }

        const error = () => {
            // console.log('Google reCaptcha error')
            context.emit('onCaptchaError')
        }
        
        useMeta({
            script: [
                { src: `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=${props.language}`, async: true, defer: true, to: 'head' }, // inside body tag
            ]
        })
        
        return {
            rendered,
            success,
            expired,
            error,
            siteKey,
        };

    },
    methods: {
        execute() {
            (this.$refs['recaptcha'] as any).execute()
        },
        reset() {
            (this.$refs['recaptcha'] as any).reset()
        }
    },
    watch: { 
        executeTrigger: function(newVal) {
            this.execute()
        },
        resetTrigger: function(newVal) {
            this.reset()
        }
    }
});
