<template>
   <div>
      <OurFinalCustomersBanner/>
      <div class="container">
         <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
            <div class="col-12">
               <h2 class="section-title text-center text-uppercase">{{ $t('market_section.title') }}</h2>
            </div>
         </div>
         <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
            <div class="col-12">
               <div class="px-5">
                  <p id="market-p-1" class="[ col-12 tw-mb-4 ] content-text text-start">
                     {{ $t('market_section.description_p1') }}
                  </p>
                  <p id="market-p-2" class="[ col-12 tw-mb-4 ] content-text text-start">
                     {{ $t('market_section.description_p2') }}
                  </p>
                  <p id="market-p-3" class="[ col-12 ] content-text text-start">
                     {{ $t('market_section.description_p3') }}
                  </p>
               </div>
            </div>
         </div>
         <div id="div-market-map" class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ]">
            <div class="row d-none [ d-sm-flex ]">
               <div id="my-container">
                  <img v-for="(flag, index) in flags" :ref="flag.id" :id="flag.id" :src="flag.src" :alt="flag.alt" :key="`flag-${index}`" class="over-map flag" data-bs-toggle="tooltip" data-bs-placement="left" xtitle="Tooltip on left" data-aos="fade-down" data-aos-duration="500" :data-aos-delay="flag.aosDelay" data-aos-anchor="#market-p-2" data-aos-anchor-placement="bottom-bottom" />
                  <!-- <img id="market-map" class="mx-auto" src="./images/map-clear-post.png" alt="market map"> -->
                  <img id="market-map" class="mx-auto" src="./images/map-clear.png" alt="market map">
               </div>
            </div>
            <div class="row d-sm-none">
               <img id="market-map-xs" class="mx-auto" src="./images/map-mobile.png" alt="market map">
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import OurFinalCustomersBanner         from '@/components/Home/OurFinalCustomersBannerComponent/OurFinalCustomersBannerComponent.vue'

export default {

   name: 'Market',
   components: {
      OurFinalCustomersBanner,
   },

   data: () => ({
      flags: [
         { aosDelay: 500, id: 'market-us-flag', src: require('./images/Bandera-usa.png'), alt: 'us flag' },
         { aosDelay: 600, id: 'market-canada-flag', src: require('./images/Bandera-canada.png'), alt: 'canada flag' },
         { aosDelay: 700, id: 'market-el-salvador-flag', src: require('./images/Bandera-el-salvador.png'), alt: 'el salvador flag' },
         { aosDelay: 800, id: 'market-costa-rica-flag', src: require('./images/Bandera-costa-rica.png'), alt: 'costa rica flag' },
         { aosDelay: 900, id: 'market-chile-flag', src: require('./images/Bandera-chile.png'), alt: 'chile flag' },
         { aosDelay: 1000, id: 'market-uk-flag', src: require('./images/Bandera-reino-unido.png'), alt: 'uk flag' },
         { aosDelay: 1100, id: 'market-sweden-flag', src: require('./images/Bandera-suecia.png'), alt: 'sweden flag' },
         { aosDelay: 1200, id: 'market-france-flag', src: require('./images/Bandera-francia.png'), alt: 'france flag' },
         { aosDelay: 1300, id: 'market-holland-flag', src: require('./images/Bandera-holanda.png'), alt: 'holland flag' },
         { aosDelay: 1400, id: 'market-japan-flag', src: require('./images/Bandera-japon.png'), alt: 'japan flag' },
      ],
   }),
   methods: {
      //
   }

}

</script>

<style lang="scss" scoped>

@keyframes bounce {
	0% {
		transform:         translateY(0)
	}
  50% {
		transform:         translateY(-15px)
	}
  100% {
		transform:         translateY(0)
	}
}

#my-container {
   position: relative;
   display: inline;
}

.over-map {
   max-width: 100%;
   position: absolute;
   z-index: 2;
}

.flag.animated {
   animation-duration: 1.5s;
   animation-timing-function: ease;
   animation-delay: 0s;
   animation-iteration-count: infinite;
   animation-name: bounce;
}

#market-us-flag {

   max-width: 55px;
   width: 7vw;
   top: 13%;
   left: 14%;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: 13%;
      left: 14%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: 13%;
      left: 13.7%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: 11%;
      left: 24.3%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: 13%;
      left: 26.8%;
   }

}

#market-canada-flag {

   max-width: 55px;
   width: 7vw;
   top: -7%;
   left: 17.5%;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: -7%;
      left: 17.5%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: -3%;
      left: 17.5%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: -5%;
      left: 26.7%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: -1%;
      left: 29.2%;
   }

}

#market-el-salvador-flag {

   max-width: 55px;
   width: 7vw;
   top: 23%;
   left: 18.7%;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: 23%;
      left: 18.7%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: 29%;
      left: 19.1%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: 28%;
      left: 28%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: 30%;
      left: 30.3%;
   }

}

#market-costa-rica-flag {

   max-width: 55px;
   width: 7vw;
   top: 40%;
   left: 19.7%;
   z-index: 3;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: 40%;
      left: 19.7%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: 43%;
      left: 19.9%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: 42%;
      left: 28.6%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: 43%;
      left: 30.8%;
   }

}

#market-chile-flag {

   max-width: 55px;
   width: 7vw;
   top: 60%;
   left: 21%;
   z-index: 4;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: 60%;
      left: 21%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: 63%;
      left: 21.5%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: 62%;
      left: 29.8%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: 62%;
      left: 31.7%;
   }

}

#market-uk-flag {

   max-width: 55px;
   width: 7vw;
   top: -12%;
   left: 43%;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: -12%;
      left: 43%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: -9%;
      left: 43.4%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: -14.3%;
      left: 44.7%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: -12%;
      left: 45.4%;
   }

}

#market-holland-flag {

   max-width: 55px;
   width: 7vw;
   top: -1%;
   left: 49.4%;
   z-index: 3;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: -1%;
      left: 49.4%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: -1%;
      left: 49.3%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: -1%;
      left: 49.4%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: 1%;
      left: 49.6%;
   }

}

#market-france-flag {

   max-width: 55px;
   width: 7vw;
   top: -1%;
   left: 45.3%;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: -1%;
      left: 45.3%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: 1%;
      left: 45.3%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: -1%;
      left: 46.4%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: 1%;
      left: 46.9%;
   }

}

#market-japan-flag {

   max-width: 55px;
   width: 7vw;
   top: 11%;
   left: 81.6%;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: 11%;
      left: 81.6%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: 13%;
      left: 82.2%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: 11%;
      left: 72.5%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: 12%;
      left: 70.6%;
   }

}

#market-sweden-flag {

   max-width: 55px;
   width: 7vw;
   top: -13%;
   left: 48%;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      top: -13%;
      left: 48%;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      top: -11%;
      left: 48.3%;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      top: -13%;
      left: 48.6%;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      top: -11%;
      left: 49%;
   }

}

#market-map {

   max-width: 100%;
   z-index: 1;
   // width: 85vw;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      // width: 600px;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      // width: 600px;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      width: 650px;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      width: 700px;
   }

}

</style>

