
<template>
    <div>
        <Preloader @startApp="startApp" />

        <Navbar
            :sectionLinksData="sectionLinksData"
            stickyStarts="always"
            :whiteThemeStarts="navbarWhiteThemeStarts"
            :activeSection="activeSection"
        />

        <header :ref="setSectionRef" id="home">
            <!-- This div is    intentionally blank. It creates the transparent black overlay over the video which you can modify in the CSS -->
            <div
                v-if="!isMobile"
                :class="{ 'd-none': headerTitleState == 0 && headerSubtitleState == 0 }"
                class="overlay"
            ></div>
            <div v-else class="overlay"></div>

            <!-- The HTML5 video element that will create the background video on the header -->
            <video
                v-if="!isMobile"
                ref="video"
                playsinline="playsinline"
                muted="muted"
                autoplay="autoplay"
                loop="loop"
            >
                <source src="./videos/intro-hd.mp4" type="video/mp4" />
                <!-- <source src="./videos/intro-black.mp4" type="video/mp4"> -->
            </video>

            <!-- The header content -->
            <div class="container h-100">
                <div class="d-flex h-100 text-center align-items-center">
                    <div class="w-100">
                        <div
                            :class="{
                                'tw-w-0': headerTitleState == 0,
                                'typewriter-animate-main-title': headerTitleState == 1,
                                'animate__animated animate__fadeOutDown': headerTitleState == 2,
                            }"
                            class="typewriter video-title"
                        >
                            {{ $t("main_page_section.main_video.title") }}
                        </div>
                        <p
                            :class="{
                                'tw-w-0': headerSubtitleState == 0,
                                'typewriter-animate-subtitle': headerSubtitleState == 1,
                                'animate__animated animate__fadeOutDown':
                                    headerSubtitleState == 2,
                            }"
                            class="typewriter video-subtitle"
                        >
                            {{ $t("main_page_section.main_video.description") }}
                        </p>
                    </div>
                </div>
            </div>
        </header>

        <div id="main-content">
            <section
                :ref="setSectionRef"
                id="wanna-sell-us-your-fruit"
                class="
                    [
                    tw-pt-24
                    ]
                    [
                    sm:xtw-pb-7
                    ]
                    [
                    md:xtw-pb-8
                    ]
                    [
                    lg:xtw-pb-12
                    ]
                    scroll-snap-y-proximity
                "
            >
                <WannaSellYourFruitBanner :isMobile="isMobile" />
            </section>

            <section
                :ref="setSectionRef"
                id="about-us"
                class="
                    [
                    tw-pt-24
                    ]
                    [
                    sm:xtw-pb-7
                    ]
                    [
                    md:xtw-pb-8
                    ]
                    [
                    lg:xtw-pb-12
                    ]
                    scroll-snap-y-proximity
                "
            >
                <AboutUs />
                <!-- <div class="container d-flex justify-content-center text-center align-items-center" style="height: 100vh;">
                             <h1 class="tw-text-3xl">NO ABOUT US CONTENT</h1>
                        </div> -->
            </section>

            <section
                :ref="setSectionRef"
                id="market"
                class="
                    [
                    tw-pt-24
                    ]
                    [
                    sm:xtw-pb-7
                    ]
                    [
                    md:xtw-pb-8
                    ]
                    [
                    lg:xtw-pb-12
                    ]
                    scroll-snap-y-proximity
                "
            >
                <Market />
                <!-- <div class="container d-flex justify-content-center text-center align-items-center" style="height: 100vh;">
                             <h1 class="tw-text-3xl">NO MARKET CONTENT</h1>
                        </div> -->
            </section>

            <section
                :ref="setSectionRef"
                id="certifications"
                class="
                    [
                    tw-pt-24
                    ]
                    [
                    sm:xtw-pb-7
                    ]
                    [
                    md:xtw-pb-8
                    ]
                    [
                    lg:xtw-pb-12
                    ]
                    scroll-snap-y-proximity
                "
            >
                <Certifications />
                <!-- <div class="container d-flex justify-content-center text-center align-items-center" style="height: 100vh;">
                             <h1 class="tw-text-3xl">NO CERTIFICATIONS CONTENT</h1>
                        </div> -->
            </section>

            <section
                :ref="setSectionRef"
                id="gallery"
                class="
                    [
                    tw-pt-24
                    ]
                    [
                    sm:xtw-pb-7
                    ]
                    [
                    md:xtw-pb-8
                    ]
                    [
                    lg:xtw-pb-12
                    ]
                    scroll-snap-y-proximity
                "
            >
                <Gallery />
                <WannaWorkWithUsBanner />
                <!-- <div class="container d-flex justify-content-center text-center align-items-center" style="height: 100vh;">
                             <h1 class="tw-text-3xl">NO GALLERY CONTENT</h1>
                        </div> -->
            </section>
        </div>

        <div
            id="container-wa-floating"
            :class="[
                { 'wa-floating-enabled': whatsappFloatingButton },
                'lang',
                this.$i18n.locale,
            ]"
        >
            <button
                @click="onWAFloatingButtonClick"
                id="wa-floating-btn"
                :class="['lang', this.$i18n.locale]"
            >
                <div>
                    <i><font-awesome-icon size="lg" :icon="['fab', 'whatsapp']" /></i
                    >&nbsp;&nbsp; {{ $t("wa_floating_banner.text") }}
                </div>
                <!-- <div v-else> <a!-- (this.$i18n.locale == 'en') --a>
                             <i><font-awesome-icon size="lg" :icon="['fab', 'whatsapp']" /></i>&nbsp;&nbsp; {{ $t('wa_floating_banner.text') }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div> -->
            </button>
        </div>

        <Footer
            :aosAnimations="true"
            class="[ tw-pt-24 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]"
        />
    </div>
</template>

<script>
/* eslint-disable */
import debounce from "lodash/debounce";
import {
    sleep,
    randomEx,
    isTouchDevice,
    isMobileDevice,
    getViewportSizeName,
} from "@/utils/shane-utils.js";

import Preloader from "@/components/Global/PreloaderComponent/PreloaderComponent.vue";
import Navbar from "@/components/Home/NavbarComponent/NavbarComponent.vue";
import AboutUs from "@/components/Home/AboutUsComponent/AboutUsComponent.vue";
import Market from "@/components/Home/MarketComponent/MarketComponent.vue";
import WannaSellYourFruitBanner from "@/components/Home/WannaSellYourFruitBannerComponent/WannaSellYourFruitBannerComponent.vue";
import Certifications from "@/components/Home/CertificationsComponent/CertificationsComponent.vue";
import Gallery from "@/components/Home/GalleryComponent/GalleryComponent.vue";
import WannaWorkWithUsBanner from "@/components/Home/WannaWorkWithUsBannerComponent/WannaWorkWithUsBannerComponent.vue";
import Footer from "@/components/Global/FooterComponent/FooterComponent.vue";

const i18nMetaPrefix = "main_page_section.meta_info";
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`;

export default {
    components: {
        Preloader,
        Navbar,
        AboutUs,
        Market,
        WannaSellYourFruitBanner,
        Certifications,
        Gallery,
        WannaWorkWithUsBanner,
        Footer,
    },
    beforeCreate() {
        document.documentElement.style.overflow = "hidden";
    },
    metaInfo() {
        return {
            title: this.$t(i18nPageTitleKey),
            htmlAttrs: {
                lang: [this.$i18n.locale],
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
                { name: "robots", content: "index, follow" },
                { name: "keywords", content: this.$t(`${i18nMetaPrefix}.keywords`) },
                { property: "og:title", content: this.$t(i18nPageTitleKey) },
                {
                    property: "og:description",
                    content: this.$t(`${i18nMetaPrefix}.description`),
                },
                { name: "author", content: "uligibson@hotmail.com" },
                { property: "og:type", content: "website" },
                { property: "og:url", content: "https://aztecavo.com.mx" },
                {
                    property: "og:image",
                    content: "https://aztecavo.com.mx/aztecavo.png",
                },
            ],
        };
    },
    setup() {
        //
    },
    data() {
        return {
            appLoaded: false,
            whatsappFloatingButton: false,
            innerWidth: window.innerWidth,
            sectionLinksData: [
                { isActive: false, href: "#home", i18nKey: "navbar.home" },
                {
                    isActive: false,
                    href: "#wanna-sell-us-your-fruit",
                    i18nKey: "navbar.wanna_sell_us_your_fruit",
                },
                { isActive: false, href: "#about-us", i18nKey: "navbar.about_us" },
                { isActive: false, href: "#market", i18nKey: "navbar.market" },
                {
                    isActive: false,
                    href: "#certifications",
                    i18nKey: "navbar.certifications",
                },
                { isActive: false, href: "#gallery", i18nKey: "navbar.gallery" },
                { isActive: false, href: "#footer", i18nKey: "navbar.contact" },
            ],
            navbarWhiteThemeStarts: 0,
            headerTitleState: 0,
            headerSubtitleState: 0,
            indexHeaderBgImg: randomEx(0, 5),
            activeSection: null,
            sectionRefs: [],
            headerBgImages: [
                require("./images/mobile-header-0.jpg"),
                require("./images/mobile-header-1.jpg"),
                require("./images/mobile-header-2.jpg"),
                require("./images/mobile-header-3.jpg"),
                require("./images/mobile-header-4.jpg"),
                require("./images/mobile-header-5.jpg"),
            ],
        };
    },
    computed: {
        cssHeaderBgImg() {
            return `url('${this.headerBgImages[this.indexHeaderBgImg]}')`;
        },
        isMobile() {
            return isMobileDevice(this.innerWidth);
            // return true
        },
        viewportSizeName() {
            return getViewportSizeName(this.innerWidth);
        },
    },
    watch: {
        //
    },
    methods: {
        startApp() {
            document.documentElement.style.overflow = "initial";
            this.doHeaderAnimations();
        },
        onWAFloatingButtonClick() {
            if (isTouchDevice()) {
                this.$router.push({ path: "/wanna-sell-your-fruit" });
            } else {
                window.open(
                    "https://api.whatsapp.com/send?phone=5214521493559&text=Hola%20Aztecavo%2C%20me%20interesa%20venderles%20mi%20fruta%20%F0%9F%A5%91%F0%9F%92%B0",
                    "_blank"
                );
            }
        },
        setSectionRef(el) {
            if (el) {
                this.sectionRefs.push(el);
            }
        },
        async doHeaderAnimations() {
            this.appLoaded = true;

            if (this.isMobile) {
                this.headerTitleState = 1;
                await sleep(3500);
                this.headerTitleState = -1;
                this.headerSubtitleState = 1;
                await sleep(3500);
                await sleep(1000);
                this.headerSubtitleState = -1;
            } else {
                this.headerTitleState = 1;
                await sleep(3500);
                this.headerTitleState = -1;
                this.headerSubtitleState = 1;
                await sleep(3500);
                await sleep(1000);
                this.headerTitleState = 2;
                await sleep(500);
                this.headerSubtitleState = 2;
                await sleep(2000);
                this.headerTitleState = 0;
                this.headerSubtitleState = 0;
            }
        },
        removeHashFromUrl() {
            const uri = window.location.toString();
            if (uri.indexOf("#") > 0) {
                const clean_uri = uri.substring(0, uri.indexOf("#"));
                window.history.replaceState({}, document.title, clean_uri);
            }
        },
        onScroll: debounce(function debounceScroll() {
            const scrollPos =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;

            for (let elem of this.sectionRefs) {
                const scrollTopMinus = scrollPos + 73; // navbar height // temp get real height from navbar component

                // temp disabled if necesary
                if (
                    elem.offsetTop <= scrollTopMinus &&
                    elem.offsetTop + elem.offsetHeight > scrollTopMinus
                ) {
                    this.activeSection = elem.id;
                    if (this.activeSection === "home") {
                        this.removeHashFromUrl()
                        this.$refs.video?.play();
                        this.whatsappFloatingButton = false;
                    } else {
                        this.$refs.video?.pause();
                        history.replaceState(null, null, `#${this.activeSection}`);
                        this.whatsappFloatingButton = true;
                    }
                    break;
                }
            }
        }, 100),
        onResize: debounce(function debounceResize() {
            this.navbarWhiteThemeStarts = this.sectionRefs[1].offsetTop - 50;
            this.innerWidth = window.innerWidth;
        }, 1000),
    },
    updated() {
        // this.scrollToAnchor()
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("scroll", this.onScroll);
        this.onScroll(); // trigger to get actual active section
        this.onResize(); // trigger to get actual values
    },
    unmounted() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("scroll", this.onScroll);
    },
};
</script>

<style lang="scss" scoped>
#container-wa-floating {
    position: fixed;
    height: 54px;
    bottom: 64px;
    z-index: 998;
    display: inline-block;
    opacity: 0;
    visibility: hidden;

    &.lang {
        transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s, width 1s,
            opacity 0.3s, visibility 0.3s;

        &.es {
            width: 288px;
            right: -245px;
        }

        &.en {
            width: 250px;
            right: -200px;
        }

        @media not all and (hover: none) {
            &:hover,
            &:hover #wa-floating-btn {
                right: 0px;
            }
        }
    }

    &.wa-floating-enabled {
        opacity: 1;
        visibility: visible;
    }
}

#wa-floating-btn {
    height: 39px;
    position: fixed;
    bottom: 71px;
    z-index: 999;
    color: white;
    background-color: #198754;
    border: 1px solid #198754;
    white-space: nowrap;
    // transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;

    /* display: inline-block; */
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    //    border-radius: 0.25rem;

    &.lang {
        transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s, width 1s,
            opacity 0.3s, visibility 0.3s;

        &.es {
            width: 284px;
            right: -248px;
        }

        &.en {
            width: 244px;
            right: -202px;
        }
    }
}
</style>


<style lang="scss" scoped>
header {
    position: relative;
    background-color: black;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;

    & video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translateX(-50%) translateY(-50%);
    }

    & .container {
        position: relative;
        z-index: 2;
    }

    & .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.5;
        z-index: 1;
    }
}

@media (pointer: coarse) and (hover: none) {
    header {
        background: v-bind(cssHeaderBgImg) black no-repeat center center scroll;
        background-size: cover;

        & video {
            display: none !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.typewriter {
    // font-size: 180%;
    // top: 50%;
    // text-align: center;
    // transform: translateY(-50%);
    position: relative;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, 0); /* invisible */
}

.typewriter-animate-main-title {
    border-right: 2px solid rgba(255, 255, 255, 0.75);
    animation: typewriter-animate-main-title 1.7s steps(22) 1s 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter-animate-main-title {
    from {
        width: 0;
    }
    to {
        width: 6.3em;
    }
}

.typewriter-animate-subtitle {
    border-right: 2px solid rgba(255, 255, 255, 0.75);
    animation: typewriter-animate-subtitle 1.7s steps(22) 500ms 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter-animate-subtitle {
    from {
        width: 0;
    }
    to {
        width: 11.2em;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
    to {
        border-right-color: transparent;
    }
}
</style>

<style lang="scss" scoped>
header {
    .video-title {
        $textShadow: 0.93px;
        font-family: "Bebas Neue", Arial, sans-serif;
        letter-spacing: 0.06em;
        font-size: 3rem;
        color: white;
        text-transform: uppercase;
        text-shadow: $textShadow $textShadow $textShadow #000;

        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) {
            font-size: 4rem;
        }
    }

    .video-subtitle {
        $textShadow: 0.93px;
        // font-family: 'Bebas Neue', Arial, sans-serif;
        // letter-spacing: 0.06em;
        // font-size: 3rem;
        text-shadow: $textShadow $textShadow $textShadow #000;
        color: white;
        margin-bottom: 0;
        font-size: 1.25rem;
        font-weight: 300;

        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) {
            // font-size: 4rem;
        }
    }
}
</style>
