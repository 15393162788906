<template>
   <div>
      <div class="container">
         <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
            <div class="col-12">
               <h2 class="section-title text-center text-uppercase">{{ $t('about_us_section.title') }}</h2>
            </div>
         </div>
         <div class="[ tw-mb-10 ] [ sm:tw-mb-10 ] [ lg:tw-mb-10 ] row justify-content-center">
            <div class="[ col-12 px-4 ] [ px-sm-3 ] [ px-md-5 ]">
               <p id="about-us-description" class="[ text-start ] [ text-md-center mx-md-auto ] content-text">
                  {{ $t('about_us_section.description') }}
               </p>
            </div>
         </div>
         <div class="[ text-center ] [ justify-content-lg-around lg:tw-mx-32 ] [ xl:tw-mx-40 ] row">
            <div class="[ col-12 tw-mb-5 ] [ col-sm-6 sm:tw-mb-0 ] [ col-lg-auto ]" data-aos="fade" data-aos-once="true" data-aos-duration="500" data-aos-delay="200" data-aos-anchor-placement="bottom-bottom">
               <router-link id="history-link" to="/our-history">
                  <div id="history-div" class="d-inline-block">
                     <img class="mx-auto tw-mb-2 img-fluid" :src="require('./images/history.svg')" alt="Nuestra historia">
                     <div class="history-p">
                        <p class="tw-text-green-700 mx-auto text-uppercase">{{ $t('about_us_section.history.title') }}</p>
                        <p class="">
                           <span class="pe-1">{{ $t('about_us_section.history.description') }}</span>
                           <span class="fa-chevron-circle-right d-inline-block">
                              <font-awesome-icon size="sm" class="tw-text-green-700" :icon="['fas', 'chevron-circle-right']" />
                           </span>
                        </p>
                     </div>
                  </div>
               </router-link>
            </div>
            <div class="[ col-12 ] [ col-sm-6 ] [ col-lg-auto ]" data-aos="fade" data-aos-once="true" data-aos-duration="500" data-aos-delay="200" data-aos-anchor-placement="bottom-bottom">
               <router-link id="philosophy-link" to="/our-philosophy">
                  <div id="philosophy-div" class="d-inline-block">
                     <img class="mx-auto tw-mb-2 img-fluid" :src="require('./images/philosophy.svg')" alt="Filosofía empresarial">
                     <div class="philosophy-p">
                        <p class="tw-text-green-700 mx-auto text-uppercase">{{ $t('about_us_section.philosophy.title') }}</p>
                        <p class="">
                           <span class="pe-1">{{ $t('about_us_section.philosophy.description') }}</span>
                           <span class="fa-chevron-circle-right d-inline-block">
                              <font-awesome-icon size="sm" class="tw-text-green-700" :icon="['fas', 'chevron-circle-right']" />
                           </span>
                        </p>
                     </div>
                  </div>
               </router-link>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {

   name: 'AboutUs',
   components: {
      //
   },
   data() {
      return {
         //
      }
   },
   methods: {
      //
   },
   mounted() {
      //
   }

}

</script>

<style lang="scss" scoped>

#about-us-description {
   
   $baseInlineSize: 660px;

   overflow-wrap: break-word;
   
   
   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      //
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      //
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      inline-size: $baseInlineSize * 1;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      inline-size: $baseInlineSize * 1;
   }

}

#history-link, #philosophy-link {
   color: #5A5A5A;
}

#history-div:hover > .history-p > p:nth-of-type(2) > .fa-chevron-circle-right {
   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {
      animation: x .4s infinite alternate ease-in;
   }
}

#philosophy-div:hover > .philosophy-p > p:nth-of-type(2) > .fa-chevron-circle-right {
   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {
      animation: x .4s infinite alternate ease-in;
   }
}

@keyframes x {
  from { transform: translateX(0px) }
  to { transform: translateX(5px) }
}

#history-div {

   $baseImgWidth: 85px;
   cursor: pointer;

   padding: 10px 20px;
   border-radius: 5px;
   transition: all 0.3s;

   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {

      &:hover {
         box-shadow: 0 1px 10px rgb(10 10 10 / 0.3);
         transform: scale(1.08);
      }

   }

   
   & img {
      width: $baseImgWidth;
   }
   
   & .history-p p {

      overflow-wrap: break-word;

      &:nth-of-type(1) {
         font-family: 'Bebas Neue', Arial, sans-serif;
         font-size: 1.87rem;
         font-weight: 600;
         inline-size: $baseImgWidth * 1.52;
         line-height: 1.87rem;
         letter-spacing: 1.32px;
      }

      &:nth-of-type(2) {
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.7rem;
      }

      & .fa-chevron-circle-right {
         transition: all .5s;
      }

   }


}

#philosophy-div {

   $baseImgWidth: 101px;
   cursor: pointer;

   padding: 10px 20px;
   border-radius: 5px;

   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {

      transition: all 0.3s;

      &:hover {
         box-shadow: 0 1px 10px rgb(10 10 10 / 0.3);
         transform: scale(1.08);
      }

   }

   
   & img {
      width: $baseImgWidth;
      margin-top: 24px;
   }
   
   & .philosophy-p p {

      &:nth-of-type(1) {
         font-family: 'Bebas Neue', Arial, sans-serif;
         font-size: 1.87rem;
         font-weight: 600;
         line-height: 1.87rem;
         inline-size: $baseImgWidth * 2;
         letter-spacing: 1.32px;
      }

      &:nth-of-type(2) {
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.7rem;
      }

   }
   
}

</style>

