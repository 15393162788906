export function sleep(milliseconds) {
  //Pausa la ejecucion determinado numero de milisegundos
  return new Promise(function (resolve) {
    return setTimeout(resolve, milliseconds);
  });
}
  /* Usage:
  sleep(500).then(() => {
      Do stuff...
  });
  ***********************************************/

export function randomEx(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function isTouchDevice() {
  return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 )
}

export function isMobileDevice(w = window.innerWidth) {
  return (isTouchDevice() && (w > 0 && w < 1200))
}

export function getViewportSizeName(w = window.innerWidth) {

  const sizes = [
    {name: 'xs', index: 0},
    {name: 'sm', index: 1},
    {name: 'md', index: 2},
    {name: 'lg', index: 3},
    {name: 'xl', index: 4}
  ]

  let res = sizes[0]

  switch (true) {
    
    case (w < 576): {
      res = sizes[0]
      break;
    }

    case (w >= 576 && w < 768): {
      res = sizes[1]
      break;
    }

    case (w >= 768 && w < 992): {
      res = sizes[2]
      break;
    }

    case (w >= 992 && w < 1200): {
      res = sizes[3]
      break;
    }

    case (w >= 1200): {
      res = sizes[4]
      break;
    }
      
    default: {
      break;
    }

  }

  return res

}