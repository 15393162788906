
<template>
  <div v-if="navbarIsSticked != null" :class="`lang-switcher tw-relative ${navbarIsSticked ? 'sticked' : ''}`">
    <button href="#" class="tw-flex tw-items-center" @click="toggleVisibility" @keydown.space.exact.prevent="toggleVisibility" @keydown.esc.exact="hideDropdown" @keydown.shift.tab="hideDropdown" @keydown.up.exact.prevent="startArrowKeys" @keydown.down.exact.prevent="startArrowKeys">
      <img :src="images[`${$i18n.locale}Flag`]" alt="flag" class="tw-w-8 tw-h-8">
      <span class="tw-ml-2">{{ $i18n.locale.toUpperCase() }}</span>
      <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"></path></svg>
    </button>
    <transition name="dropdown">
      <ul v-click-away="hideDropdown" v-if="isVisible" ref="dropdown" class="tw-absolute tw-p-0 tw-normal-case tw-font-normal xs:tw-left-0 xl:tw-right-0 tw-shadow tw-overflow-hidden tw-w-48 tw-py-1">
        <li>
          <a href="#" @click.prevent="setLocale('en')" ref="account" class="tw-flex tw-items-center tw-px-3 tw-py-3" @keydown.up.exact.prevent="" @keydown.tab.exact="focusNext(false)" @keydown.down.exact.prevent="focusNext(true)" @keydown.esc.exact="hideDropdown">
            <img :src="images.enFlag" alt="english flag" class="tw-h-8 tw-w-8">
            <span class="tw-ml-2">English</span>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="setLocale('es')" class="tw-flex tw-items-center tw-px-3 tw-py-3" @keydown.shift.tab="focusPrevious(false)" @keydown.up.exact.prevent="focusPrevious(true)" @keydown.down.exact.prevent="" @keydown.tab.exact="hideDropdown" @keydown.esc.exact="hideDropdown">
            <img :src="images.esFlag" alt="mexico flag" class="tw-h-8 tw-w-8">
            <span class="tw-ml-2">Spanish</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'LanguageSwitcher',
  props: {
    navbarIsSticked: Boolean
  },
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      images: {
        enFlag: require('./images/en-flag.svg'),
        esFlag: require('./images/es-flag.svg'),
      }
    }
  },
  computed: {
    //
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible
    },
    showDropdown() {
      this.isVisible = true
    },
    hideDropdown() {
      this.isVisible = false
      this.focusedIndex = 0
    },
    startArrowKeys() {
      if (this.isVisible) {
        // this.$refs.account.focus()
        this.$refs.dropdown.children[0].children[0].focus()
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus()
    },
    setLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('lang', locale)
      // this.$router.push({
      //   params: { lang: locale }
      // })
      this.hideDropdown()
    }

  }
}
</script>

<style lang="scss" scoped>

  $normalSpanColor: #fff;
  $stickedSpanColor: #222;

  .lang-switcher {

  // & button span {

  // }

  // & ul li a span {

  // }

  & ul {
    // background-color: #00000085;
    background-color: #000000c7;
    margin-top: 0.4rem;
    border: 1px solid #7e7e7e6b;
    border-radius: 0;
    transition: all .3s ease-out 0s;

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      //
    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      margin-top: 0.35rem;
    }

    @media (min-width: 992px) {
      //
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
      margin-top: 1.3rem;
      border-radius: 0.25rem;
      background-color: #00000085;
    }

    & li {

      &:hover {
        background-color: #9b9b9b24;
      }

      &:active {
        background-color: #9b9b9b38;
      }

    }

  }

  & span {
    color: $normalSpanColor;
    transition: all .3s ease-out 0s;
  }

  &.sticked {

    & ul {
      background-color: #ffffffcf;
      margin-top: 23px;
      border: 1px solid #5c5c5c6b;
      transition: all .3s ease-out 0s;

      /********************* */
      @media only screen and (max-width:991px) {
        margin-top: 5px;
      }

      & li {

        &:hover {
          background-color: #9b9b9b24;
        }

        &:active {
          background-color: #9b9b9b38;
        }

      }
      /********************* */
    }

    & span {
      color: $stickedSpanColor;
    }

  }

}

</style>

<style scoped>

  .dropdown-enter-active,
  .dropdown-leave-active {
    transition: all .3s ease-in-out;
  }

  .dropdown-enter-from,
  .dropdown-leave-to {
    opacity: 0;
    transform: translateY(-12px);
  }

</style>


