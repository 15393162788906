<template>
   <div v-if="(loadState < 4)" :class="{'fadeOut': (loadState == 3)}" class="preloader">
        <div :class="{'d-none': (loadState > 2), 'fadeOut': (loadState == 2)}" class="wrapper">
            <div class="spinner"></div>
        </div>   
   </div>
</template>

<script>

import { sleep } from '@/utils/shane-utils.js'

export default {
    name: 'Preloader',
    data() {
        return {
            loadState: 1,
        }
    },
    watch: {
        loadState(newVal) { // watch it
            if(newVal === 4) {
                this.$emit('startApp')
            }
        }
    },
    mounted() {
        if (document.readyState == "complete") {
            this.loadState = 4
        }
        else {
            document.onreadystatechange = async () => {
                if (document.readyState == "complete") { 
                    
                    this.loadState = 2

                    await sleep(700)

                    this.loadState = 3

                    await sleep(500)

                    this.loadState = 4
                } 
            }
        }
    }
}

</script>

<style scoped>

.fadeOut {
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeOut;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%,#25b09b);
    mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    to{
        transform: rotate(1turn);
    }
}

.preloader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    transition: all .5s;
}

.wrapper{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}

</style>