<template>
   <div id="div-gallery" class="container">
      <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
         <div class="col-12">
            <div>
               <h2 id="gallery-title" class="section-title text-center pb-2 text-uppercase">{{ $t('gallery_section.title') }}</h2>
            </div>
         </div>
      </div>
      <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
         <div class="col-12">
            <div>
               <p id="gallery-subtitle" class="content-text text-center">
                  {{ $t('gallery_section.description') }}
               </p>
            </div>
         </div>
      </div>
      <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
         <div class="col-12">
            <vue-picture-swipe class="gallery-container" :items="items" :options="{shareEl: false}"></vue-picture-swipe>
         </div>
      </div>
      <div class="row tw-pb-10">
         <div class="col-6">
            <router-link to="/presentation-video">
               <img class="tw-cursor-pointer" :src="videoThumbnail" alt="Aztecavo video">
            </router-link>
         </div>
      </div>
   </div>
</template>

<style lang="scss" scoped>

.gallery-container {

   /* MAX Medium devices (tablets starts at 768px) */
   @media (max-width: 767px) {
      text-align: center;
   }

}

</style>

<script>

import VuePictureSwipe from 'vue3-picture-swipe';

export default {

   name: 'Gallery',
   components: {
      //
   },
   data() {
      return {
         videoThumbnail: require('./images/video-thumbnail.jpg'),
         items: [
            { alt: 'Antes de llegar-1',         src: require('./images/1. Antes de llegar/image-1.jpg'),             w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/1. Antes de llegar/image-1[thumb].jpg') },
            { alt: 'Antes de llegar-2',         src: require('./images/1. Antes de llegar/image-2.jpg'),             w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/1. Antes de llegar/image-2[thumb].jpg') },
            { alt: 'Antes de llegar-3',         src: require('./images/1. Antes de llegar/image-3.jpg'),             w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/1. Antes de llegar/image-3[thumb].jpg') },
            { alt: 'Antes de llegar-4',         src: require('./images/1. Antes de llegar/image-4.jpg'),             w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/1. Antes de llegar/image-4[thumb].jpg') },
            { alt: 'Antes de llegar-5',         src: require('./images/1. Antes de llegar/image-5.jpg'),             w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/1. Antes de llegar/image-5[thumb].jpg') },
            { alt: 'Recepcion de fruta-1',      src: require('./images/2. Recepcion de fruta/image-1.jpg'),          w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/2. Recepcion de fruta/image-1[thumb].jpg') },
            { alt: 'Seleccion-1',               src: require('./images/3. Seleccion/image-1.jpg'),                   w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/3. Seleccion/image-1[thumb].jpg') },
            { alt: 'Seleccion-2',               src: require('./images/3. Seleccion/image-2.jpg'),                   w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/3. Seleccion/image-2[thumb].jpg') },
            { alt: 'Proceso-1',                 src: require('./images/4. Proceso/image-1.jpg'),                     w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/4. Proceso/image-1[thumb].jpg') },
            { alt: 'Proceso-2',                 src: require('./images/4. Proceso/image-2.jpg'),                     w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/4. Proceso/image-2[thumb].jpg') },
            { alt: 'Proceso-3',                 src: require('./images/4. Proceso/image-3.jpg'),                     w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/4. Proceso/image-3[thumb].jpg') },
            { alt: 'Proceso-4',                 src: require('./images/4. Proceso/image-4.jpg'),                     w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/4. Proceso/image-4[thumb].jpg') },
            { alt: 'Proceso-5',                 src: require('./images/4. Proceso/image-5.jpg'),                     w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/4. Proceso/image-5[thumb].jpg') },
            { alt: 'Proceso-6',                 src: require('./images/4. Proceso/image-6.jpg'),                     w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/4. Proceso/image-6[thumb].jpg') },
            { alt: 'Proceso-7',                 src: require('./images/4. Proceso/image-7.jpg'),                     w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/4. Proceso/image-7[thumb].jpg') },
            { alt: 'Empacado-1',                src: require('./images/5. Empacado/image-1.jpg'),                    w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/5. Empacado/image-1[thumb].jpg') },
            { alt: 'Empacado-2',                src: require('./images/5. Empacado/image-2.jpg'),                    w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/5. Empacado/image-2[thumb].jpg') },
            { alt: 'Empacado-3',                src: require('./images/5. Empacado/image-3.jpg'),                    w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/5. Empacado/image-3[thumb].jpg') },
            { alt: 'Empacado-4',                src: require('./images/5. Empacado/image-4.jpg'),                    w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/5. Empacado/image-4[thumb].jpg') },
            { alt: 'Empacado-5',                src: require('./images/5. Empacado/image-5.jpg'),                    w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/5. Empacado/image-5[thumb].jpg') },
            { alt: 'Despues del empacado-1',    src: require('./images/6. Despues del empacado/image-1.jpg'),        w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/6. Despues del empacado/image-1[thumb].jpg') },
            { alt: 'Despues del empacado-2',    src: require('./images/6. Despues del empacado/image-2.jpg'),        w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/6. Despues del empacado/image-2[thumb].jpg') },
            { alt: 'Despues del empacado-3',    src: require('./images/6. Despues del empacado/image-3.jpg'),        w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/6. Despues del empacado/image-3[thumb].jpg') },
            { alt: 'Despues del empacado-4',    src: require('./images/6. Despues del empacado/image-4.jpg'),        w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/6. Despues del empacado/image-4[thumb].jpg') },
            { alt: 'Personal-1',                src: require('./images/Personal/image-1.jpg'),                       w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Personal/image-1[thumb].jpg') },
            { alt: 'Personal-2',                src: require('./images/Personal/image-2.jpg'),                       w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Personal/image-2[thumb].jpg') },
            { alt: 'Personal-3',                src: require('./images/Personal/image-3.jpg'),                       w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Personal/image-3[thumb].jpg') },
            { alt: 'Otras-1',                   src: require('./images/Otras/image-1.jpg'),                          w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Otras/image-1[thumb].jpg') },
            { alt: 'Otras-2',                   src: require('./images/Otras/image-2.jpg'),                          w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Otras/image-2[thumb].jpg') },
            { alt: 'Otras-3',                   src: require('./images/Otras/image-3.jpg'),                          w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Otras/image-3[thumb].jpg') },
            { alt: 'Otras-4',                   src: require('./images/Otras/image-4.jpg'),                          w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Otras/image-4[thumb].jpg') },
            { alt: 'Otras-5',                   src: require('./images/Otras/image-5.jpg'),                          w: 1920,    h: 1280,    thumbnail: require('./images/thumbnails/Otras/image-5[thumb].jpg') },
         ],
      }
   },

}

</script>