<template>
   <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] container-fluid">
      <div class="row">
         <div id="our-products-div" class="">
            <div id="our-products-text">
               <h3 class="tw-mb-4 mx-auto tw-text-green-700" data-aos="fade-up" data-aos-duration="700" data-aos-delay="500" data-aos-anchor="#our-products-div" data-aos-anchor-placement="center-bottom text-uppercase">{{ $t('market_section.end_customers.banner.title') }}</h3>
               <!-- <p class="mx-auto tw-mt-1 tw-mb-3" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="1000" data-aos-anchor="#our-products-div" data-aos-anchor-placement="center-bottom">Proveemos fruta de la más alta calidad</p> -->
               <!-- <router-link to="/our-final-customers">
                  <button class="btn btn-our-products btn-success" data-aos="fade-right" data-aos-duration="500" data-aos-delay="1000" data-aos-anchor="#our-products-div" data-aos-anchor-placement="center-bottom">{{ $t('market_section.end_customers.banner.button_text') }} &nbsp;<font-awesome-icon :icon="['fas', 'store']" /></button>
               </router-link> -->
            </div>
         </div>         
      </div>
   </div>
</template>

<style lang="scss" scoped>

#our-products-div {
   
   $baseHeight: 320px;

   width: 100%;
   height: $baseHeight;
   background: #fff url('./images/our-products.png') no-repeat center center;
   background-size: cover;
   text-align: center;
   position: relative;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      height: $baseHeight * 1.3;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      height: $baseHeight * 1.5;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      height: $baseHeight * 1.6;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      height: calc(100vh - 72px);
   }

   & #our-products-text {

      position: absolute;
      transform: translate(-50%,-50%);
      overflow-wrap: break-word;
      left: 30%;
      top: 50%;

      /* Small devices (landscape phones, 576px and up) */
      @media (min-width: 576px) {
         left: 24%;
      }

      /* Medium devices (tablets, 768px and up) */
      @media (min-width: 768px) {
         left: 24%;
      }

      /* Large devices (desktops, 992px and up) */
      @media (min-width: 992px) {
         left: 21%;
      }

      /* Extra large devices (large desktops, 1200px and up) */
      @media (min-width: 1200px) {
         left: 21%;
      }

      & h3 {

         $baseFontSize: 2.2rem;
         $baseLineH: 2.3rem;
         $baseInlineSize: 160px;

         font-family: 'Bebas Neue', Arial, sans-serif;
         font-weight: 700;
         font-size: $baseFontSize;
         line-height: $baseLineH;
         inline-size: $baseInlineSize;
         letter-spacing: 1px;

         /* Small devices (landscape phones, 576px and up) */
         @media (min-width: 576px) {
            font-size: $baseFontSize * 1.3;
            line-height: $baseLineH * 1.3;
            inline-size: $baseInlineSize * 1.3;
         }

         /* Medium devices (tablets, 768px and up) */
         @media (min-width: 768px) {
            font-size: $baseFontSize * 1.5;
            line-height: $baseLineH * 1.5;
            inline-size: $baseInlineSize * 1.5;
         }

         /* Large devices (desktops, 992px and up) */
         @media (min-width: 992px) {
            font-size: $baseFontSize * 1.6;
            line-height: $baseLineH * 1.6;
            inline-size: $baseInlineSize * 1.6;
         }

         /* Extra large devices (large desktops, 1200px and up) */
         @media (min-width: 1200px) {
            font-size: $baseFontSize * 1.8;
            line-height: $baseLineH * 1.8;
            inline-size: $baseInlineSize * 1.8;
         }
      }

      & p {

         $baseFontSize: 0.7rem;
         $baseLineH: 1rem;
         $baseInlineSize: 150px;

         font-size: $baseFontSize;
         inline-size: $baseInlineSize;
         line-height: $baseLineH;

         /* Small devices (landscape phones, 576px and up) */
         @media (min-width: 576px) {
            font-size: $baseFontSize * 1.3;
            line-height: $baseLineH * 1.3;
            inline-size: $baseInlineSize * 1.3;
         }

         /* Medium devices (tablets, 768px and up) */
         @media (min-width: 768px) {
            font-size: $baseFontSize * 1.5;
            line-height: $baseLineH * 1.5;
            inline-size: $baseInlineSize * 1.5;
         }

         /* Large devices (desktops, 992px and up) */
         @media (min-width: 992px) {
            font-size: $baseFontSize * 1.6;
            line-height: $baseLineH * 1.6;
            inline-size: $baseInlineSize * 1.6;
         }

         /* Extra large devices (large desktops, 1200px and up) */
         @media (min-width: 1200px) {
            font-size: $baseFontSize * 1.8;
            line-height: $baseLineH * 1.8;
            inline-size: $baseInlineSize * 1.8;
         }
      }

   }

}

.btn-our-products {

   $basePaddingY: 0.25rem;
   $basePaddingX: 0.5rem;
   $baseFontSize: .875rem;
   $baseBorderRadius: 0.2rem;

   padding: $basePaddingY $basePaddingX;
   font-size: $baseFontSize;
   border-radius: $baseBorderRadius;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      padding: ($basePaddingY * 1.1) ($basePaddingX * 1.1);
      font-size: $baseFontSize * 1.1;
      border-radius: $baseBorderRadius * 1.1;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      padding: ($basePaddingY * 1.2) ($basePaddingX * 1.2);
      font-size: $baseFontSize * 1.2;
      border-radius: $baseBorderRadius * 1.2;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      padding: ($basePaddingY * 1.3) ($basePaddingX * 1.3);
      font-size: $baseFontSize * 1.3;
      border-radius: $baseBorderRadius * 1.3;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      padding: ($basePaddingY * 1.5) ($basePaddingX * 1.5);
      font-size: $baseFontSize * 1.5;
      border-radius: $baseBorderRadius * 1.5;
   }
}

</style>


<script>

export default {

   name: 'OurFinalCustomersBanner'

}

</script>

