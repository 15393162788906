<template>

    <div>
        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" :activeSection="activeSection" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">
                    <h1 class="main-title text-center text-uppercase">{{ $t('privacy_notice_section.privacy_notice') }}</h1>
                </div>
            </div>
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">

                    <p class="mb-4" v-html="$t('privacy_notice_section.p_1')"></p>

                    <h2 class="mb-3"><b>{{ $t('privacy_notice_section.h2_1') }}</b></h2>

                    <p>{{ $t('privacy_notice_section.p_2') }}</p>
                    <ul class="tw-list-disc tw-ml-8">
                        <li><p class="mb-2">{{ $t('privacy_notice_section.p_3') }}</p></li>
                        <li><p class="mb-2">{{ $t('privacy_notice_section.p_4') }}</p></li>
                        <li><p class="mb-2">{{ $t('privacy_notice_section.p_5') }}</p></li>
                        <li><p>{{ $t('privacy_notice_section.p_6') }}</p></li>
                    </ul>

                    <h2 class="mt-4 mb-3"><b>{{ $t('privacy_notice_section.h2_2') }}</b></h2>

                    <p v-html="$t('privacy_notice_section.p_7')"></p>

                    <p>{{ $t('privacy_notice_section.p_8') }}</p>
                    <ul class="tw-list-disc tw-ml-8">
                        <li><p class="mb-2">{{ $t('privacy_notice_section.p_9') }}</p></li>
                        <li><p class="mb-2">{{ $t('privacy_notice_section.p_10') }}</p></li>
                        <li><p>{{ $t('privacy_notice_section.p_11') }}</p></li>
                    </ul>

                    <h2 class="mt-4 mb-3"><b>{{ $t('privacy_notice_section.h2_3') }}</b></h2>

                    <p v-html="$t('privacy_notice_section.p_12')"></p>

                    <h2 class="mt-4 mb-3"><b>{{ $t('privacy_notice_section.h2_4') }}</b></h2>

                    <p v-html="$t('privacy_notice_section.p_13')"></p>

                    <h2 class="mt-4 mb-3"><b>{{ $t('privacy_notice_section.h2_5') }}</b></h2>

                    <p></p>
                    {{ $t('privacy_notice_section.p_14') }}

                    <p v-html="$t('privacy_notice_section.p_15')"></p>

                    <h2 class="mt-4 mb-3"><b>{{ $t('privacy_notice_section.p_16') }}</b></h2>

                    <p v-html="$t('privacy_notice_section.p_17')"></p>

                    <h2 class="mt-4 mb-3"><b>{{ $t('privacy_notice_section.h2_6') }}</b></h2>

                    <p>{{ $t('privacy_notice_section.p_18') }}</p>

                    <p v-html="$t('privacy_notice_section.p_19', ['10/01/2022'])"></p>

                </div>
            </div>
        </div>


        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style scoped>

h2 {
    font-size: 1.2rem;
    /* margin-bottom: 1rem; */
}

p {
    margin-bottom: 1rem;
}

</style>

<script>


import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'

const i18nMetaPrefix = 'privacy_notice_section.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'PrivacyNotice',
    components: {
        Navbar,
        Footer,
    },
    metaInfo () { 
        return { 

            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
               lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
               { name: 'robots', content: 'index, follow'},
               { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
               { property: 'og:title', content: this.$t(i18nPageTitleKey)},
               { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
               { name: 'author', content: 'uligibson@hotmail.com'},
               { property: 'og:type', content: 'website'},
               { property: 'og:url', content: 'https://aztecavo.com.mx/privacy-notice'},
               { property: 'og:image', content: 'https://aztecavo.com.mx/aztecavo.png'},
            ],
        }
    },
    setup() {
        //
    },
    data() {
        return {
            sectionLinksData: [
               { isActive: true,  href: '',           i18nKey: 'navbar.privacy_notice' },
            ],
            navbarwhiteThemeStarts: null,
            activeSection: null,
        }
    },
    methods: {
        //
    },
    watch: {
        //
    },
    computed: {
        //
    },
    mounted() {
        window.scrollTo(0,0)
    }
}

</script>