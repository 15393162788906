<template>
   <footer id="footer">
      <div class="[ pb-5 pt-4 ] [ pt-md-5 ] footer">
         <div id="footer-content" class="container">
            <div class="row justify-content-around">
               <div class="[ col-12 tw-mb-12 tw-px-6 ] [ col-sm-6 sm:tw-pr-6 sm:tw-mb-8 sm:tw-px-0 ] [ col-md-6 md:tw-pl-3 md:tw-pr-10 md:tw-mb-8 ] [ col-lg-4 lg:tw-mb-12 lg:tw-pl-0 lg:tw-pr-10 ] [ col-xl-4 xl:tw-pl-0 xl:tw-pr-16 xl:tw-px-0 ]" :data-aos="aosAnimations ? 'fade' : ''" data-aos-once="true" data-aos-duration="500" data-aos-delay="150" data-aos-anchor="#footer-content" data-aos-anchor-placement="top-bottom">
                  <h3 class="footer-subtitle tw-mb-6 footer-title"><span class="d-none d-sm-inline-block">|&nbsp;</span>{{ $t('footer_section.main_office.title') }}</h3>
                  <p class="[ tw-mb-2 ]">
                     <i>-</i>&nbsp; {{ $t('footer_section.main_office.city') }}
                  </p>
                  <p @click="goToWithBlank('https://goo.gl/maps/f8RwSovsQgoR7jWi7')" class="[ tw-mb-3 ] tw-cursor-pointer footer-location-link">
                     <a class="" href="https://goo.gl/maps/f8RwSovsQgoR7jWi7" target="_BLANK">
                        <font-awesome-icon size="lg" :icon="['fas', 'map-marker-alt']" />
                     </a>&nbsp; {{ $t('footer_section.main_office.address') }}
                  </p>
                  <p @click="goToWithBlank('tel:524526906600')" class="[ tw-mb-3 ] tw-cursor-pointer footer-phone-link">
                     <a class="" href="tel:524526906600" target="_BLANK">
                        <font-awesome-icon size="lg" :icon="['fas', 'phone']" />
                     </a>&nbsp; {{ $t('footer_section.main_office.phone_number') }}
                  </p>
                  <p @click="goToWithBlank('mailto:oficina@aztecavo.com.mx')" class="tw-cursor-pointer footer-mail-link">
                     <a class="" href="mailto:oficina@aztecavo.com.mx" target="_BLANK">
                        <font-awesome-icon size="lg" :icon="['fas', 'envelope']" />
                     </a>&nbsp; {{ $t('footer_section.main_office.email') }}
                  </p>
               </div>
               <div class="[ col-12 tw-mb-12 tw-px-6 ] [ col-sm-6 sm:tw-mb-8 sm:tw-px-0 ] [ col-md-6 md:tw-mb-8 ] [ col-lg-4 lg:tw-pl-0 lg:tw-pr-10 lg:tw-mb-12 ] [ col-xl-4 xl:tw-pl-0 xl:tw-pr-16 ]" :data-aos="aosAnimations ? 'fade' : ''" data-aos-once="true" data-aos-duration="500" data-aos-delay="150" data-aos-anchor="#footer-content" data-aos-anchor-placement="top-bottom">
                  <h3 class="footer-subtitle tw-mb-6 footer-title"><span class="d-none d-sm-inline-block">|&nbsp;</span>{{ $t('footer_section.other_offices.title') }}</h3>
                  <p class="tw-mb-2">
                     <i>-</i>&nbsp; {{ $t('footer_section.other_offices.city_1') }}
                  </p>
                  <p @click="goToWithBlank('https://goo.gl/maps/hDvMDZ8MAUjfxXrq7')" class="tw-cursor-pointer tw-mb-8 footer-location-link">
                     <a class="" href="https://goo.gl/maps/hDvMDZ8MAUjfxXrq7" target="_BLANK">
                        <font-awesome-icon size="lg" :icon="['fas', 'map-marker-alt']" />
                     </a>&nbsp; {{ $t('footer_section.other_offices.address_1') }}
                  </p>
                  <p class="tw-mb-2">
                     <i>-</i>&nbsp; {{ $t('footer_section.other_offices.city_2') }}
                  </p>
                  <p @click="goToWithBlank('https://goo.gl/maps/27wHBwnUPZgs9oA8A')" class="tw-cursor-pointer footer-location-link">
                     <a class="" href="https://goo.gl/maps/27wHBwnUPZgs9oA8A" target="_BLANK">
                        <font-awesome-icon size="lg" :icon="['fas', 'map-marker-alt']" />
                     </a>&nbsp; {{ $t('footer_section.other_offices.address_2') }}
                  </p>
               </div>
               <div class="[ col-12 tw-mb-12 tw-px-6 ] [ col-sm-6 sm:tw-pr-6 sm:tw-mb-8 sm:tw-px-0 ] [ col-md-6 md:tw-pl-3 md:tw-pr-10 md:tw-mb-6 ] [ col-lg-4 lg:tw-mb-8 lg:tw-px-0 ] [ col-xl-auto xl:tw-px-0 ] footer-our-platforms" :data-aos="aosAnimations ? 'fade' : ''" data-aos-once="true" data-aos-duration="500" data-aos-delay="200" data-aos-anchor="#footer-content" data-aos-anchor-placement="top-bottom">
               <!-- <div data-aos="fade" data-aos-once="true" data-aos-duration="500" data-aos-delay="200" data-aos-anchor="#footer-content" data-aos-anchor-placement="top-bottom" class="[ col-12 mb-5 px-4 ] [ col-sm-5  mb-sm-2 px-sm-0 ] [ col-md-4 mb-md-3 ] [ col-lg-3 mb-lg-0 ] footer-our-platforms"> -->
                  <h3 class="footer-subtitle tw-mb-6 footer-title"><span class="d-none d-sm-inline-block">|&nbsp;</span>{{ $t('footer_section.our_websites.title') }}</h3>
                  <p class="tw-mb-5"><a href="https://productores.aztecavo.com.mx/" target="_BLANK"><font-awesome-icon :icon="['fas', 'link']" />&nbsp; {{ $t('footer_section.our_websites.growers') }}</a></p>
                  <p class="tw-mb-5"><a href="https://proveedores.aztecavo.com.mx/" target="_BLANK"><font-awesome-icon :icon="['fas', 'link']" />&nbsp; {{ $t('footer_section.our_websites.providers') }}</a></p>
                  <!-- <p><a href="https://www.blog-aztecavo.mx/" target="_BLANK"><font-awesome-icon :icon="['fas', 'link']" />&nbsp; {{ $t('footer_section.our_websites.blog') }}</a></p> -->
                  <p><a href="https://blog-aztecavo.com.mx/" target="_BLANK"><font-awesome-icon :icon="['fas', 'link']" />&nbsp; {{ $t('footer_section.our_websites.blog') }}</a></p>
               </div>
               <div class="d-none d-xl-block w-100"></div>
               <div class="[ col-12 tw-mb-12 tw-px-6 ] [ col-sm-6 sm:tw-mb-8 sm:tw-px-0 ] [ col-md-6 md:tw-mb-6 ] [ col-lg-4 lg:tw-text-center lg:tw-mt-0 lg:tw-mx-auto lg:tw-mb-0  ] [ col-xl-auto xl:tw-text-center xl:tw-mt-0 xl:tw-mx-auto ]" :data-aos="aosAnimations ? 'fade' : ''" data-aos-once="true" data-aos-duration="500" data-aos-delay="250" data-aos-anchor="#footer-content" data-aos-anchor-placement="top-bottom">
                  <h3 class="footer-subtitle tw-mb-6 footer-title"><span class="d-none d-sm-inline-block d-lg-none">|&nbsp;</span>{{ $t('footer_section.our_social_networks.title') }}</h3>
                  <div class="footer-social-media">
                     <a class="me-3" href="https://www.facebook.com/aztecavo/" target="_BLANK">
                        <img class="" src="./images/facebook.png" alt="Facebook">
                     </a>
                     <a class="me-3" href="https://instagram.com/aztecavo" target="_BLANK">
                        <img class="" src="./images/instagram.png" alt="Instagram">
                     </a>
                     <a class="me-3" href="https://www.linkedin.com/company/aztecavo-sapi/about/" target="_BLANK">
                        <img class="" src="./images/linkedin.png" alt="LinkedIn">
                     </a>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center">
            </div>
            <div class="[ text-center ] [ mt-sm-2 ] [ mt-md-3 ] [ mt-lg-5 ] row footer-bottom">
               <div class="col-12 copyright">
                  <p></p>
               </div>
            </div>
            <div class="justify-content-center tw-my-3 row footer-bottom">
               <div class="[ col-auto ] small-link">
                  <router-link to="/privacy-notice">{{ $t('footer_section.privacy_notice') }}</router-link>
               </div>
               <div class="[ col-auto ] small-link">
                  <router-link to="/cookies-policy">{{ $t('footer_section.cookies_policy') }}</router-link>
               </div>
               <div class="[ col-auto ] small-link">
                  <router-link to="/code-of-ethics">{{ $t('footer_section.code_of_ethics') }}</router-link>
               </div>
               <div class="[ col-auto ] small-link">
                  <router-link to="/environmental-policy">{{ $t('footer_section.environmental_policy') }}</router-link>
               </div>
            </div>
            <div class="text-center footer-bottom">
               <p class="mt-2">{{ $t('footer_section.all_rights_reserved', [2022]) }}</p>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>

export default {
   name: 'Footer',
   props: {
      aosAnimations: {
         type: Boolean,
         default: () => true,
         required: false
      }
   },
   mounted() {
      let myScript = document.createElement('script')
      myScript.setAttribute('type', 'text/javascript')
      myScript.setAttribute('id', 'hs-script-loader')
      myScript.setAttribute('async', '')
      myScript.setAttribute('defer', '')
      myScript.setAttribute('src', '//js-na1.hs-scripts.com/21689283.js')
      document.head.appendChild(myScript)
   },
   methods: {
      goToWithBlank(url) {
         window.open(url, '_blank');
      }
   }
}
</script>


<style lang="scss" scoped>

p {
   font-size: 16px;
}

#footer-content {

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      //
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      //
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      //
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      max-width: 1170px;
   }
}

.footer {
   background: #232323;
   color     : #9c9c9c;
   font-family: 'Roboto', sans-serif;

   & .footer-title {
      color: #fff;
   }

}

h3.footer-subtitle {
   color: #fff;
   // font-family: poppins, sans-serif;
   font-weight: 500;
   font-size: 1.4rem;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      font-size: 1.4rem;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      font-size: 1.5rem;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      font-size: 1.5rem;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      font-size: 1.6rem;
   }

}


.footer-location-link {

   & > a {
      color: #dbdbdb;
   }

   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {

      & > a {
         transition: color 0.3s;
      }

      &:hover > a {
         color: #F23F3B;
      }

   }

   // smartphones, touchscreens, stylus-based screens, Nintendo Wii controller, Microsoft Kinect
   // This media query excludes devices that can easily hover (Laptops, Desktop PC's)
   @media (hover: none) and (pointer: coarse), // or
   (hover: none) and (pointer: fine), // or
   (hover: hover) and (pointer: coarse) {

      & > a {
         color: #F23F3B;
      }

   }
   
}

.footer-phone-link {

   & > a {
      color: #dbdbdb;
   }

   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {

      & > a {
         transition: color 0.3s;
      }

      &:hover > a {
         color: #2c9931;
      }

   }

   // smartphones, touchscreens, stylus-based screens, Nintendo Wii controller, Microsoft Kinect
   // This media query excludes devices that can easily hover (Laptops, Desktop PC's)
   @media (hover: none) and (pointer: coarse), // or
   (hover: none) and (pointer: fine), // or
   (hover: hover) and (pointer: coarse) {

      & > a {
         color: #2c9931;
      }

   }
   
}

.footer-mail-link {

   & > a {
      color: #dbdbdb;
   }

   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {

      & > a {
         transition: color 0.3s;
      }

      &:hover > a {
         color: #F2BF40;
      }

   }

   // smartphones, touchscreens, stylus-based screens, Nintendo Wii controller, Microsoft Kinect
   // This media query excludes devices that can easily hover (Laptops, Desktop PC's)
   @media (hover: none) and (pointer: coarse), // or
   (hover: none) and (pointer: fine), // or
   (hover: hover) and (pointer: coarse) {

      & > a {
         color: #F2BF40;
      }

   }
   
}

.footer-our-platforms a {

   color: #9c9c9c;
   position: relative;
   transition: color 0.3s;

   &:hover {
      color:#dbdbdb;
   }

   &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 2.1px;
      background-color: #dbdbdb;
      transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
   }

   &:hover::before {

      @media (hover: hover) and (pointer: fine) {
         left: 0;
         right: auto;
         width: 100%;
      }

   }

}

.footer-social-media > a > img {

   $width: 2.75rem;

   width: $width;

   // ONLY devices that can hover easily (Desktop PC, Laptop)
   @media (hover: hover) and (pointer: fine) {

      filter: grayscale(1);
      transition: all 0.3s;

      &:hover {
         transform: scale(1.4);
         filter: grayscale(0);
      }

   }

   // smartphones, touchscreens, stylus-based screens, Nintendo Wii controller, Microsoft Kinect
   // This media query excludes devices that can easily hover (Laptops, Desktop PC's)
   @media (hover: none) and (pointer: coarse), // or
   (hover: none) and (pointer: fine), // or
   (hover: hover) and (pointer: coarse) {

      //

   }

}

.footer-bottom {

   & a {
      color: #9C9C9C;
      transition: color 0.3s;

      &:hover {
         color: #DBDBDB;
      }
   }

   & .copyright p {
      border-top: 1px solid rgba(255, 255, 255, .1);
   }

   & .small-link {
      font-size: 0.8rem;
   }

}


</style>