<template>

    <div>
        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-6 ] [ sm:tw-pb-6 ] [ lg:tw-pb-6 ] row justify-content-center">
                <div class="col-12">
                    <h1 class="main-title text-center text-uppercase">{{ $t('market_section.end_customers.title') }}</h1>
                </div>
            </div>
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-auto col-final-customer-logo" v-for="(image, index) in images" :key="`image-${index}`">
                    <img :src="image.src" :alt="image.alt" class="final-customer-logo" data-bs-toggle="tooltip" data-bs-placement="left" xtitle="Tooltip on left" data-aos="zoom-out" data-aos-once="true" data-aos-duration="500" :data-aos-delay="image.delay" />
                </div>
            </div>
        </div>

        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style lang="scss" scoped>

.final-customer-logo {
    padding: 10px 0px;
    width: 200px;
    height: auto;
}

.col-final-customer-logo {
    transition: all .3s;

    &:hover {
        transform: scale(1.2);
    }
}

</style>

<script>

import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'

const i18nMetaPrefix = 'market_section.end_customers.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'OurFinalCustomers',
    components: {
        Navbar,
        Footer,
    },
    metaInfo () { 
        return {
            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
                lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
                { name: 'robots', content: 'index, follow'},
                { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
                { property: 'og:title', content: this.$t(i18nPageTitleKey)},
                { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
                { name: 'author', content: 'uligibson@hotmail.com'},
                { property: 'og:type', content: 'website'},
                { property: 'og:url', content: 'https://aztecavo.com.mx/our-final-customers'},
                { property: 'og:image', content: 'https://aztecavo.com.mx/nuestros-clientes-finales.png'},
            ],
        }
    },
    setup() {
        //
    },
    data() {
        return {
            sectionLinksData: [
               { isActive: true,  href: '',           i18nKey: 'navbar.our_final_customers' },
            ],
            images: [
                { delay: 500, alt: 'Albertsons',        src: require('./images/albertsons.png') },
                { delay: 600, alt: 'Costco',            src: require('./images/costco.png') },
                { delay: 700, alt: 'FOOD LION',         src: require('./images/food-lion.png') },
                { delay: 800, alt: 'FRESH THYME',       src: require('./images/fresh-thyme.png') },
                { delay: 900, alt: 'HEB',               src: require('./images/heb.png') },
                { delay: 1000, alt: 'Kroger',            src: require('./images/kroger.png') },
                { delay: 1100, alt: 'SAFEWAY',           src: require('./images/safeway.png') },
                { delay: 1200, alt: 'Sams',              src: require('./images/sams.png') },
                { delay: 1300, alt: 'Sprouts',           src: require('./images/sprouts.png') },
                { delay: 1400, alt: 'Trader Joes',       src: require('./images/trader-joes.png') },
                { delay: 1500, alt: 'Walmart',           src: require('./images/walmart.png') },
                { delay: 1600, alt: 'WHOLE FOODS',       src: require('./images/whole-foods.png') },
            ],
        }
    },
    watch: {
        //
    },
    mounted() {
        // window.scrollTo(0, 0)
    }
}

</script>