<template>

    <div>
        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" />
        <div class="container tw-mt-24">
            <div class="[ tw-pb-14 ] [ sm:tw-pb-10x ] [ lg:tw-pb-10x ] row justify-content-center">
                <div class="col-12">
                    <h1 class="main-title text-center text-uppercase">{{ $t('sell_us_your_fruit_section.sell_us_your_fruit') }}</h1>
                </div>
            </div>
        </div>
        <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] container">
            <div class="row justify-content-around">
                <div class="[ col-12 tw-mb-16 ] [ col-sm-12 ] [ col-md-4 ] [ col-lg-4 ] [ col-xl-4 ] justify-content-center text-center">
                    <h2 class="tw-mb-14">{{ $t('sell_us_your_fruit_section.send_us_a_whatsapp') }}</h2>
                    <a href="https://api.whatsapp.com/send?phone=5214521493559&text=Hola%20Aztecavo%2C%20me%20interesa%20venderles%20mi%20fruta%20%F0%9F%A5%91%F0%9F%92%B0" class="btn-whatsapp-pulse mx-auto">
                        <i><font-awesome-icon :icon="['fab', 'whatsapp']" /></i>
                    </a>
                </div>
                <div class="[ col-12 text-center tw-mb-10 ] [ col-sm-12 ] [ col-md-auto ] [ col-lg-auto ] [ col-xl-auto ]">
                    <h2 class="section-subtitle text-uppercase">{{ $t('sell_us_your_fruit_section.or') }}</h2>
                </div>
                <div class="[ col-12 ] [ col-sm-12 ] [ col-md-5 ] [ col-lg-5 ] [ col-xl-5 ]">
                    <h2 class="text-center tw-mb-8">{{ $t('sell_us_your_fruit_section.leave_us_your_info') }}</h2>
                    <form accept-charset="UTF-8" method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit" class="contact1-form validate-form mx-auto sell-us-your-fruit-form">

                        <div class="wrap-input1">
                            <input v-model="v$.contactName.$model" :class="{'invalid': (v$.contactName.$errors.length > 0)}" class="input1" type="text" name="name" autocomplete="name" :placeholder="$t('sell_us_your_fruit_section.form.contact_name')">
                            <div class="invalid-message" v-for="(error, index) of v$.contactName.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <input v-model="v$.email.$model" :class="{'invalid': (v$.email.$errors.length > 0)}" class="input1" type="text" name="email" autocomplete="email" :placeholder="$t('sell_us_your_fruit_section.form.email')">
                            <div class="invalid-message" v-for="(error, index) of v$.email.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <input v-model="v$.orchardLocation.$model" :class="{'invalid': (v$.orchardLocation.$errors.length > 0)}" class="input1" type="text" name="city" autocomplete="off" :placeholder="$t('sell_us_your_fruit_section.form.orchard_city')">
                            <div class="invalid-message" v-for="(error, index) of v$.orchardLocation.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <input v-model="v$.orchardName.$model" :class="{'invalid': (v$.orchardName.$errors.length > 0)}" class="input1" type="text" name="orchard" autocomplete="off" :placeholder="$t('sell_us_your_fruit_section.form.orchard_name')">
                            <div class="invalid-message" v-for="(error, index) of v$.orchardName.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <input v-model="v$.phone.$model" :class="{'invalid': (v$.phone.$errors.length > 0)}" class="input1" type="text" name="phone" autocomplete="tel" :placeholder="$t('sell_us_your_fruit_section.form.phone_number')">
                            <div class="invalid-message" v-for="(error, index) of v$.phone.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <textarea maxlength="255" v-model="v$.message.$model" class="input1" name="message" autocomplete="off" :placeholder="$t('sell_us_your_fruit_section.form.message')"></textarea>
                        </div>

                        <GoogleRecaptcha :resetTrigger="captcha.triggers.reset" :language="this.$i18n.locale" @onCaptchaSuccess="onCaptchaSuccess" @onCaptchaExpired="onCaptchaExpired" @onCaptchaError="onCaptchaError"></GoogleRecaptcha>
                        <div class="invalid-message" v-for="(error, index) of v$.captcha.token.$errors" :key="index">
                            {{ error.$message }}
                        </div>

                        <div class="container-btn-round tw-mt-8">
                            <button  class="btn-round btn-green btn-icon-anim">
                                <span>{{ $t('sell_us_your_fruit_section.form.submit') }} &nbsp;<i><font-awesome-icon size="sm" class="" :icon="['fas', 'long-arrow-alt-right']" /></i></span>
                            </button>
                            <div v-if="(v$.$errors.length > 0)" class="invalid-message mt-3">
                                {{ this.$t('validations.complete_all_fields') }}
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>

        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style lang="scss" scoped>

//
    
</style>
    
<script>

import { sleep } from '@/utils/shane-utils.js';
import apiClient from '@/services/api.js';

import useVuelidate from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import { required, maxLength, email } from '@/utils/i18n-validators'

import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'
import      GoogleRecaptcha   from   '@/components/Global/GoogleRecaptchaComponent/GoogleRecaptchaComponent.vue'


const i18nMetaPrefix = 'sell_us_your_fruit_section.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'WannaSellYourFruit',
    components: {
        GoogleRecaptcha,
        Navbar,
        Footer,
    },
    metaInfo () { 
        return {
            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
                lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
                { name: 'robots', content: 'index, follow'},
                { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
                { property: 'og:title', content: this.$t(i18nPageTitleKey)},
                { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
                { name: 'author', content: 'uligibson@hotmail.com'},
                { property: 'og:type', content: 'website'},
                { property: 'og:url', content: 'https://aztecavo.com.mx/wanna-sell-your-fruit'},
                { property: 'og:image', content: 'https://aztecavo.com.mx/vendenos-tu-fruta-aguacate-aztecavo.png'},
            ],
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            captcha: {
                token: '',
                triggers: {
                    reset: 0
                },
            },
            contactName: '',
            email: '',
            orchardLocation: '',
            orchardName: '',
            phone: '',
            message: '',
            sectionLinksData: [
                { isActive: true,  href: '',           i18nKey: 'navbar.sell_us_your_fruit' },
            ],
        }
    },
    methods: {
        onCaptchaSuccess(token) {
            this.captcha.token = token
        },
        onCaptchaExpired() {
            this.captcha.token = ''
        },
        onCaptchaError() {
            this.captcha.token = ''
        },
        onSubmit() {

            // this.loading = true; // can use this to triggle a :disabled on login button

            // set all fields to touched
            this.v$.$touch()

            // stop here if form is invalid
            if (this.v$.$invalid) {
                return
            }

            this.$swal({
                icon: 'info',
                // title: ``,
                html: `
                    <div class="h4 mb-3 justify-content-center">${this.$t('swal_general.sending_data_alert.title')}</div>
                    <div class="mb-2 justify-content-center">${this.$t('swal_general.sending_data_alert.message')}</div>
                `,
                // footer: `
                //     <div class="h5 text-success">
                //         <i class="fas fa-search">&nbsp;</i> <span>Text</span>
                //     </div>
                // `,
                // confirmButtonText: '<i class="fas fa-save">&nbsp;</i> Almacenar',
                showConfirmButton: true,
                showCancelButton: false,
                showCloseButton: false,
                showLoaderOnConfirm: true,
                allowEscapeKey: () => !this.$swal.isLoading(),
                allowOutsideClick: () => !this.$swal.isLoading(),
                // customClass: {
                //     confirmButton: 'btn btn-swal2 btn-success'
                // },
                // buttonsStyling: false,
                showClass: {
                    popup: 'animate__animated animate__zoomIn animate__fast'
                },
                hideClass: {
                    popup: 'animate__animated animate__zoomOut animate__fast'
                },
                didOpen: () => {
                    // topModalRemoveTabIndex()
                    this.$swal.getConfirmButton().click()
                },
                didClose: () => {
                    // topModalAddTabIndex()
                },
                preConfirm: () => {

                    console.log('Getting authorization...')

                    return apiClient.get('/sanctum/csrf-cookie').then(response => {
                        console.log('Authorization response:')
                        console.log(response)
                        if(response.status === 204) { // success
                            console.log('Now sending data...');
                            let formData = new FormData();
                            formData.append('captchaToken', this.captcha.token);
                            formData.append('contactName', this.contactName);
                            formData.append('email', this.email);
                            formData.append('orchardLocation', this.orchardLocation);
                            formData.append('orchardName', this.orchardName);
                            formData.append('phone', this.phone);
                            formData.append('message', this.message);
                            return apiClient.post('/api/sell-us-your-fruit', formData).then((response2) => {
                                console.log('Server response:');
                                console.log(response2);
                                return response2
                            }).catch(error => {
                                console.log(error);
                            })
                        } else {
                            return response
                        }
                    }).catch(error => {
                        console.log(error);
                    })
        
                }
            }).then((response) => {

                if(response.value.data.success) {

                    this.clearForm()

                    sleep(200).then(() => {
                        this.$swal.fire({
                            icon: 'success',
                            html: `
                                <div class="h3 pb-3 fw-bold">${this.$t('sell_us_your_fruit_section.swal.data_sent_alert.title')}</div>
                                <div class="text-start mb-2">${this.$t('sell_us_your_fruit_section.swal.data_sent_alert.message')}</div>
                            `,
                            confirmButtonText: this.$t('sell_us_your_fruit_section.swal.data_sent_alert.confirm_btn_text'),
                            // cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                            showCloseButton: true,
                            allowEscapeKey: true,
                            allowOutsideClick: true,
                            // showLoaderOnConfirm: true,
                            customClass: {
                                confirmButton: 'btn btn-swal2 btn-success',
                                cancelButton: 'btn btn-swal2 btn-grey'
                            },
                            buttonsStyling: false,
                            showClass: {
                                popup: 'animate__animated animate__zoomIn animate__fast'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__zoomOut animate__fast'
                            },
                            didOpen: () => {
                                // topModalRemoveTabIndex()
                                // Swal.getConfirmButton().focus()
                            },
                            didClose: () => {
                                // topModalAddTabIndex()
                            }
                        
                        })
                    })

                } else {

                    this.$swal({
                        icon: 'error',
                        html: `
                            <div class="h2 mb-0 pb-2 font-weight-bold">${this.$t('swal_general.error_alert.title')}</div>
                            <div class="text-start mt-4 mb-4">${this.$t('swal_general.error_alert.message')}</div> 
                            <div class="text-start text-danger mt-4 mb-3"> ${response.value.data.message} </div>
                        `,
                        showConfirmButton: true,
                        confirmButtonText: this.$t('swal_general.error_alert.confirm_btn_text'),
                        showCancelButton: false,
                        showCloseButton: true,
                        customClass: {
                            confirmButton: 'btn btn-swal2 btn-danger',
                            // cancelButton: 'btn btn-swal2 btn-grey'
                        },
                        buttonsStyling: false,
                        showClass: {
                            popup: 'animate__animated animate__zoomIn animate__fast'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__zoomOut animate__fast'
                        },
                        didOpen: () => {
                            // topModalRemoveTabIndex()
                        },
                        didClose: () => {
                            // topModalAddTabIndex()
                        }
                    })
                }
            
            })
        },
        clearForm() {
            // reset form validation errors
            this.v$.$reset()

            this.captcha.triggers.reset++
            this.captcha.token = ''
            this.contactName = ''
            this.email = ''
            this.orchardLocation = ''
            this.orchardName = ''
            this.phone = ''
            this.message = ''
            
        }
    },
    watch: {
        //
    },
    validations () {
        return {
            contactName: { 
                required 
            },
            email: { 
                required, email 
            },
            orchardLocation: { 
                required 
            },
            orchardName: { 
                required 
            },
            phone: { 
                required 
            },
            message: { 
                maxLength: maxLength(255)
            },
            captcha: {
                token: {
                    required: helpers.withMessage(() => this.$t('validations.recaptcha'), required)
                }
            }
        }
    },
    mounted() {
        // temp window.scrollTo(0, 0)
    }
}

</script>

<style lang="scss" scoped>

@font-face {
    font-family: Montserrat-Bold;
    src        : url(fonts/montserrat/Montserrat-Bold.ttf)
}

@font-face {
    font-family: Montserrat-ExtraBold;
    src        : url(fonts/montserrat/Montserrat-ExtraBold.ttf)
}

@font-face {
    font-family: Montserrat-Medium;
    src        : url(fonts/montserrat/Montserrat-Medium.ttf)
}

.contact1 {
    width          : 100%;
    min-height     : 100%;
    padding        : 15px;
    background     : #fff;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
    align-items    : center
}

.container-contact1 {
    width          : 1163px;
    background     : #fff;
    border-radius  : 10px;
    overflow       : hidden;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-between;
    align-items    : center;
    padding        : 0 185px 0 170px
}

.contact1-form {
    width: 390px
}

.input1::placeholder {
    color: #999
}

textarea.input1 {
    min-height   : 90px;
    border-radius: 25px;
    padding      : 12px 30px
}

textarea.input1::placeholder {
    color: #999
}

.wrap-input1 {
    position     : relative;
    width        : 100%;
    z-index      : 1;
    margin-bottom: 20px
}

.input1 {
    height       : 50px;
    border-radius: 25px;
    padding      : 0 22px;
    display    : block;
    width      : 100%;
    background-color : #efefef;
    font-family: Montserrat-Bold;
    font-size  : 15px;
    line-height: 1.5;
    color      : #666;
    border: 1px solid #66666659;
    transition: border .3s, background-color .3s, box-shadow .3s;

    &.invalid {
        border: 2px solid #dc3545;
        padding-right: 3em;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: right 1.1em center;
        background-size: 1.425rem 1.425rem;

        &:focus {
            background-color: #f9f9f9;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.2);
            border-color: rgba(255, 0, 0, 0.2);
        }

        &~.invalid-message {
            display: block;
        }
    }

    &:focus {
        background-color: #f9f9f9;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(157 157 157 / 51%);
        border-color: rgb(157 157 157 / 51%);
    }
}

.invalid-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
    padding-left: 24px;
}

.container-btn-round {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center
}

.btn-round {
    min-width         : 193px;
    height            : 50px;
    border-radius     : 25px;
    font-family       : Montserrat-Bold;
    font-size         : 15px;
    line-height       : 1.5;
    color             : #fff;
    display           : flex;
    justify-content   : center;
    align-items       : center;
    padding           : 0 25px;
    transition        : all .4s;

    &:disabled {
        cursor: default;
        // background-color: #777;
        opacity: .65;
    }

    & i {
        display: inline-block
    }
}

.btn-icon-anim {

    & i {
        // margin-left       : 7px;
        transition        : transform .4s
    }

    &:hover:not([disabled]) > span > i {
        transform: translateX(10px)
    }
}

.btn-green {
    background        : #198754;

    &:hover:not([disabled]) {
        background: #157347
    }
}

.btn-blue {
    background        : #3490DC;

    &:hover:not([disabled]) {
        background: #227DC7
    }
}

@media (max-width:1200px) {
    .contact1-form {
        //
    }
}

@media (max-width:992px) {
    .container-contact1 {
        padding: 90px 0 0 90px
    }

    .contact1-form {
        width: 55%
    }
}

@media (max-width:768px) {
    .container-contact1 {
        padding: 0 80px 0 80px
    }

    .contact1-form {
        width: 100%
    }
}

@media (max-width:576px) {
    .container-contact1 {
        padding: 0 15px 0 15px
    }
}
</style>

<style lang="scss" scoped>

.btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	font-size: 40px * 2;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 35px * 2;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 (14px * 2) rgba(37, 211, 102, 0);
	}
}

</style>
