<template>

    <div>
        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" :activeSection="activeSection" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">
                    <h1 class="main-title text-center text-uppercase">{{ $t('environmental_policy_section.environmental_policy') }}</h1>
                </div>
            </div>
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">

                    <!-- <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_1') }}</b></h2> -->                    

                    <p class="mb-4" v-html="$t('environmental_policy_section.p_1')"></p>

                    <p class="mb-4" v-html="$t('environmental_policy_section.p_2')"></p>

                    <ul class="tw-list-disc tw-ml-8 mb-4">
                        
                        <li><p class="mb-2">{{ $t('environmental_policy_section.p_3') }}</p></li>
                        <li><p class="mb-2">{{ $t('environmental_policy_section.p_4') }}</p></li>
                        <li><p class="mb-2">{{ $t('environmental_policy_section.p_5') }}</p></li>
                    </ul>                

                </div>
            </div>
        </div>


        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style scoped>

h2 {
    font-size: 1.5rem;
    /* margin-bottom: 1rem; */
}

p {
    margin-bottom: 1rem;
}

</style>

<script>


import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'

const i18nMetaPrefix = 'environmental_policy_section.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'EnvironmentalPolicy',
    components: {
        Navbar,
        Footer,
    },
    metaInfo () { 
        return { 

            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
               lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
               { name: 'robots', content: 'index, follow'},
               { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
               { property: 'og:title', content: this.$t(i18nPageTitleKey)},
               { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
               { name: 'author', content: 'uligibson@hotmail.com'},
               { property: 'og:type', content: 'website'},
               { property: 'og:url', content: 'https://aztecavo.com.mx/environmental-policy'},
               { property: 'og:image', content: 'https://aztecavo.com.mx/aztecavo.png'},
            ],
        }
    },
    setup() {
        //
    },
    data() {
        return {
            sectionLinksData: [
               { isActive: true,  href: '',           i18nKey: 'navbar.environmental_policy' },
            ],
            navbarwhiteThemeStarts: null,
            activeSection: null,
        }
    },
    methods: {
        //
    },
    watch: {
        //
    },
    computed: {
        //
    },
    mounted() {
        window.scrollTo(0,0)
    }
}

</script>