
<template>
    <div :class="{show: !cookiesAccepted}" class="alert text-xl-center cookiealert" role="alert">
        <span class="me-xl-3">{{ $t('cookies_section.banner.text1') }} <router-link to="/cookies-policy">{{ $t('cookies_section.banner.cookies_policy') }}</router-link>.</span>
        <button @click="acceptCookies" type="button" class="btn btn-accept">{{ $t('cookies_section.banner.button_text') }}</button>
    </div>
</template>

<script>
export default {
    name: 'CookiesAlert',
    props: {
        //
    },
    data() {
        return {
            cookiesAccepted: true
        }
    },
    computed: {
        //
    },
    methods: {
        acceptCookies() {
            this.cookiesAccepted = true
        },
        checkIfCookiesWereAccepted() {
            setTimeout(() => {
                this.cookiesAccepted = localStorage.getItem('cookiesAccepted') || false
            }, 2000)
        }
    },
    watch: {
        cookiesAccepted(newValue, oldValue) {
            localStorage.setItem('cookiesAccepted', newValue)
        }
    },
    mounted() {
        this.checkIfCookiesWereAccepted()
    }
}
</script>

<style lang="scss" scoped>

a {

    color: #4CAF50;
    text-decoration: underline;
    transition: color 0.3s;

    &:hover {
        color: #087f23;
    }
}

.cookiealert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
    padding: 10px 10px;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    border-radius: 0;
    transform: translateY(100%);
    transition: all 500ms ease-out;
    font-size: 15px;
    color: #ddd;
    background: #000000e0;

    &.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
        transition-delay: 1000ms;
    }

}

.btn-accept {

    color: white;
    font-size: 14px;
    padding: 3px 6px;
    background-color: #0b6620;
    border-radius: 0.2rem;

    &:hover {
        background-color: #075a1a;
    }

    &:focus {
        box-shadow: 0 0 0 0.13rem rgb(8 203 50 / 25%);
    }
}

</style>