<template>

    <div>
        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" :activeSection="activeSection" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">
                    <h1 class="main-title text-center text-uppercase">{{ $t('code_of_ethics_section.code_of_ethics') }}</h1>
                </div>
            </div>
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">

                    <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_1') }}</b></h2>

                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_1')"></p>

                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_2')"></p>

                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_3')"></p>

                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_4')"></p>

                    <p class="mb-4">{{ $t('code_of_ethics_section.p_5') }}</p>

                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_6')"></p>

                    <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_2') }}</b></h2>
                    <ul class="tw-list-disc tw-ml-8 mb-4">
                        <li><p class="mb-4" v-html="$t('code_of_ethics_section.p_7')"></p></li>
                        <li><p class="mb-4" v-html="$t('code_of_ethics_section.p_8')"></p></li>
                        <li><p class="mb-2">{{ $t('code_of_ethics_section.p_9') }}</p></li>
                        <li><p class="mb-2">{{ $t('code_of_ethics_section.p_10') }}</p></li>
                        <li><p class="mb-2">{{ $t('code_of_ethics_section.p_11') }}</p></li>
                        <li><p class="mb-2">{{ $t('code_of_ethics_section.p_12') }}</p></li>
                        <li><p class="mb-2">{{ $t('code_of_ethics_section.p_13') }}</p></li>
                        <li><p class="mb-2">{{ $t('code_of_ethics_section.p_14') }}</p></li>
                    </ul>

                    <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_3') }}</b></h2>

                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_15')"></p>

                    <p class="mb-4">{{ $t('code_of_ethics_section.p_16') }}</p>

                    <p class="mb-4">{{ $t('code_of_ethics_section.p_17') }}</p>

                    <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_4') }}</b></h2>
                    <h2 class="mb-4"><b>{{ $t('code_of_ethics_section.h2_5') }}</b></h2>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_18')"></p>
                    <h2 class="mb-4"><b>{{ $t('code_of_ethics_section.h2_6') }}</b></h2>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_19')"></p>
                    <h2 class="mb-4"><b>{{ $t('code_of_ethics_section.h2_7') }}</b></h2>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_20')"></p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_21')"></p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_22')"></p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_23')"></p>
                    <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_8') }}</b></h2>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_24') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_25')"></p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_26') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_27') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_28')"></p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_29') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_30') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_31') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_32')"></p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_33') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_34') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_35')"></p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_36') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_37') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_38') }}</p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_39') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_40')"></p>
                    <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_9') }}</b></h2>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_41') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_42')"></p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_43') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_44')"></p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_45') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_46') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_47') }}</p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_48') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_49')"></p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_50') }}</p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_51') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_52')"></p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_53') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_54')"></p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_55') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_56') }}</p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_57') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_58') }}</p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_59')"></p>
                    <p class="mb-4" v-html="$t('code_of_ethics_section.p_60')"></p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_61') }}</p>
                    <p class="mb-4 tw-font-bold">{{ $t('code_of_ethics_section.p_62') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_63') }}</p>
                    <h2 class="mb-4 text-center"><b>{{ $t('code_of_ethics_section.h2_10') }}</b></h2>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_64') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_65') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_66') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_67') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_68') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_69') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_70') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_71') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_72') }}</p>
                    <p class="mb-4">{{ $t('code_of_ethics_section.p_73') }}</p>


                </div>
            </div>
        </div>


        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style scoped>

h2 {
    font-size: 1.5rem;
    /* margin-bottom: 1rem; */
}

p {
    margin-bottom: 1rem;
}

</style>

<script>


import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'

const i18nMetaPrefix = 'code_of_ethics_section.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'CodeOfEthics',
    components: {
        Navbar,
        Footer,
    },
    metaInfo () { 
        return { 

            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
               lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
               { name: 'robots', content: 'index, follow'},
               { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
               { property: 'og:title', content: this.$t(i18nPageTitleKey)},
               { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
               { name: 'author', content: 'uligibson@hotmail.com'},
               { property: 'og:type', content: 'website'},
               { property: 'og:url', content: 'https://aztecavo.com.mx/code-of-ethics'},
               { property: 'og:image', content: 'https://aztecavo.com.mx/aztecavo.png'},
            ],
        }
    },
    setup() {
        //
    },
    data() {
        return {
            sectionLinksData: [
               { isActive: true,  href: '',           i18nKey: 'navbar.code_of_ethics' },
            ],
            navbarwhiteThemeStarts: null,
            activeSection: null,
        }
    },
    methods: {
        //
    },
    watch: {
        //
    },
    computed: {
        //
    },
    mounted() {
        window.scrollTo(0,0)
    }
}

</script>