<template>

    <div>
        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-6 ] [ sm:tw-pb-6 ] [ lg:tw-pb-6 ] row justify-content-center">
                <div class="col-12">
                    <img id="main-img" :src="historyImg[$i18n.locale].src" :alt="$t(historyImg.alt_i18nKey)" class="mx-auto" datax-aos="zoom-out" data-aos-once="true" xdata-aos-duration="500" data-aos-delay="300" />
                </div>
            </div>
        </div>

        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style lang="scss" scoped>

#main-img {

    max-width: 100%;
    height: auto;

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
        //
    }

   /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
        //
    }

   /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        max-width: 800px;
    }

   /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        //
    }
}

</style>

<script>

import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'

const i18nMetaPrefix = 'about_us_section.history'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'History',
    components: {
        Navbar,
        Footer,
    },
    metaInfo () { 
        return {
            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
                lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
                { name: 'robots', content: 'index, follow'},
                { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
                { property: 'og:title', content: this.$t(i18nPageTitleKey)},
                { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
                { name: 'author', content: 'uligibson@hotmail.com'},
                { property: 'og:type', content: 'website'},
                { property: 'og:url', content: 'https://aztecavo.com.mx/our-history'},
                // { property: 'og:image', content: 'https://aztecavo.com.mx/aztecavo.png'},
            ],
        }
    },
    setup() {
        //
    },
    data() {
        return {
            sectionLinksData: [
               { isActive: true,  href: '', i18nKey: 'navbar.our_history' },
            ],
            historyImg: {
                es: {
                    src: require(`./images/our-history-es.png`),
                },
                en: {
                    src: require(`./images/our-history-en.png`),
                },
                alt_i18nKey: 'about_us_section.history.alt_main_image',
            },
        }
    },
    watch: {
        //
    },
    mounted() {
        // this.imageDefault.src = require(`./images/our-history-${this.$i18n.locale}.png`)
        // this.imageDefault.alt = ''
        // window.scrollTo(0, 0)
    }
}

</script>