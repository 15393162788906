import { createStore } from "vuex";

// Create a new store instance.
const store = createStore({
    state () {
        return {
            count: 0
        }
    },
    mutations: {
        increment (state) {
            state.count++
        }
    },
    actions: {
        //
    },
    getters: {
        //
    },
    modules: {
        //
    },
    strict: process.env.NODE_ENV !== 'production'
});

export default store