<template>
    <div ref="header_navbar" id="header_navbar" :class="{'sticky': isSticked, 'white-theme': whiteTheme }" class="header_navbar sticky">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav class="navbar navbar-expand-xxl">
                        <i v-if="backArrow" @click="onBackArrowClick" id="back-arrow" class="me-md-4"><font-awesome-icon :icon="['fas', 'arrow-circle-left']" size="2x" /></i>
                        <router-link to="/">
                            <a class="navbar-brand">
                                <img id="logo" :src="this.logoImg" alt="Logo">
                            </a>
                        </router-link>
                        <button class="navbar-toggler x collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <div class="navbar-collapse collapse" id="navbarSupportedContent">
                            <ul id="nav" class="navbar-nav ms-auto">
                                <li class="nav-item" v-for="(elem, index) in sectionLinksData" :key="`elem-${index}`">
                                    <a v-smooth-scroll="{ offset: 0 }" :class="{'active': elem.isActive}" class="nav-link page-scroll" :href="elem.href">{{ $t(elem.i18nKey) }}</a>
                                </li>
                                <li class="fix-top-margin-ls nav-item">
                                    <LanguageSwitcher :navbarIsSticked="whiteTheme"/>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import LanguageSwitcher from '@/components/Home/LanguageSwitcherComponent/LanguageSwitcherComponent.vue'
    import debounce from 'lodash/debounce'

    export default {
        name: 'Navbar',
        components: {
            LanguageSwitcher,
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from;
            });
        },
        mounted() {

            if(this.stickyStarts === 'always') {
                this.isSticked = true
            }
            else if(this.stickyStarts === 'never') {
                this.isSticked = false
            }

            if(this.whiteThemeStarts === 'always') {
                this.logoImg = this.normalLogoImg
                this.whiteTheme = true
            }
            else if(this.whiteThemeStarts === 'never') {
                this.logoImg = this.whiteLogoImg
                this.whiteTheme = false
            }

            window.addEventListener("scroll", this.onScroll)
            window.addEventListener("resize", this.onScroll)
            this.onScroll() // trigger to get actual active section
            this.onResize() // trigger to get actual values
        },
        unmounted() {
            window.removeEventListener("scroll", this.onScroll);
            window.removeEventListener("resize", this.onScroll);
        },
        props: {
            stickyStarts: {
                type: [Number, String],
                default: () => 'never',
                required: false
            },
            whiteThemeStarts: {
                type: [Number, String],
                default: () => 'never',
                required: false
            },
            activeSection: {
                type: [String, null],
                default: () => null,
                required: false
            },
            sectionLinksData: {
                type: [Array, null],
                default: () => null,
                required: false
            },
            backArrow: {
                type: [Boolean, null],
                default: () => false,
                required: false
            }
        },
        computed: {
            //
        },
        data() {
            return {
                whiteLogoImg: require('./images/logo-white.png'),
                normalLogoImg: require('./images/logo.png'),
                logoImg: null,
                navbarHeight: null,
                isSticked: false,
                whiteTheme: false,
            }
        },
        watch: {
            activeSection(newVal) { // watch it
                this.updateActiveSection(newVal)
            }
        },
        methods: {
            scrollTop() {
                window.scrollTo(0,0)
            },
            onBackArrowClick() {
                if(window.history.length > 2) {
                    this.$router.go(-1)
                } else {
                    this.$router.push('/')
                }
            },
            updateActiveSection: debounce(function debounceUpdateActiveSection(activeSection) {
                if(this.sectionLinksData != null) {
                    for (let elem of this.sectionLinksData) {
                        if(elem.href === `#${activeSection}`) {
                            elem.isActive = true
                        } else {
                            elem.isActive = false
                        }
                    }
                }
            }, 100),
            onScroll: debounce(function debounceScroll() {

                if(typeof(this.stickyStarts) == 'number') {
                    if (window.pageYOffset > this.stickyStarts) {
                        this.isSticked = true
                    } else {
                        this.isSticked = false
                    }
                }

                if(typeof(this.whiteThemeStarts) == 'number') {
                    if(window.pageYOffset > this.whiteThemeStarts - this.navbarHeight) {
                        this.whiteTheme = true
                        this.logoImg = this.normalLogoImg
                    } else {
                        this.whiteTheme = false
                        this.logoImg = this.whiteLogoImg
                    }
                }

            }, 10),
            onResize: debounce(function debounceResize() {

                this.navbarHeight = this.$refs.header_navbar.offsetHeight

            }, 1000),
        },
    }

</script>


<style lang="scss" scoped>

#back-arrow {

    color: #3d8641;
    transition: all .3s;
    cursor: pointer;

    &:hover {
        color: #2e7031;
    }
}

.navbar-toggler .icon-bar {
    width             : 22px;
    height            : 2px;
    background-color  : #ffffffcf;
    display           : block;
    margin            : 4px 0;
    position          : relative;
    border-radius     : 1px;
    transition        : all .2s
}
/* ANIMATED X */
.navbar-toggler.x .icon-bar:nth-of-type(1) {
	transform: rotate(45deg);
	transform-origin: 10% 10%;
}

.navbar-toggler.x .icon-bar:nth-of-type(2) {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggler.x .icon-bar:nth-of-type(3) {
	transform: rotate(-45deg);
	transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggler.x.collapsed .icon-bar:nth-of-type(1) {
	transform: rotate(0);
}

.navbar-toggler.x.collapsed .icon-bar:nth-of-type(2) {
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggler.x.collapsed .icon-bar:nth-of-type(3) {
	transform: rotate(0);
}
/* END ANIMATED X */
</style>

<style lang="scss" scoped>

.fix-top-margin-ls {
    margin-top: 0.4rem;
}

.header_navbar {
    position          : absolute;
    top               : 0;
    left              : 0;
    width             : 100%;
    z-index           : 100;
    transition        : all .3s ease-out 0s
}

.navbar {
    padding           : 20px 0;
    border-radius     : 5px;
    position          : relative;
    transition        : all .3s ease-out 0s
}

.navbar-brand {
    margin: 0;
    padding: 0;
}

.navbar-brand img {
    width: 150px
}

.navbar-toggler {
    padding: 0
}

.navbar-toggler:focus {
    box-shadow        : none
}

.navbar-nav .nav-item {
    position   : relative;
    margin-left: 40px
}

// @media (min-width:992px) {
//     .navbar-nav .nav-item {
//         margin-left: 30px
//     }
// }

// @media (max-width:991px) {
//     .navbar-nav .nav-item {
//         margin: 0
//     }
// }

.navbar-nav .nav-item span {
    color: #fff;
}

.navbar-nav .nav-item a {
    font-size         : 16px;
    font-weight       : 400;
    color             : #fff;
    transition        : all .3s ease-out 0s;
    padding           : 10px 0;
    position          : relative
}

.navbar-nav .nav-item a::before {
    position          : absolute;
    content           : '';
    bottom            : 0;
    left              : 0;
    width             : 0;
    height            : 3px;
    background-color  : #fff;
    transition        : all .3s ease-out 0s
}

@media (max-width: 1399px) {
    
    .navbar-nav .nav-item a::before {
        display: none
    }

    .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
        color  : #f3f3f3
    }

    .navbar-nav .nav-item a.active,
    .navbar-nav .nav-item a:hover {
        color: #6aff6e
    }

    .navbar-nav .nav-item:hover .sub-menu {
        top: 0
    }

    .navbar-nav .nav-item .sub-menu {
        position  : relative;
        width     : 100%;
        top       : 0;
        display   : none;
        opacity   : 1;
        visibility: visible
    }

    .navbar-nav .sub-nav-toggler {
        display   : block;
        position  : absolute;
        right     : 0;
        top       : 0;
        background: 0 0;
        color     : #222;
        font-size : 18px;
        border    : 0;
        width     : 30px;
        height    : 30px
    }

    .navbar-collapse {
        position          : absolute;
        top               : 100%;
        left              : 0;
        width             : 100%;
        background-color: #000000c7;
        border            : 1px solid #7e7e7e6b;
        z-index           : 100;
        box-shadow        : 0 15px 20px 0 rgba(0, 0, 0, .1);
        padding           : 5px 12px
    }

}

.navbar-nav .nav-item a.active::before,
.navbar-nav .nav-item a:hover::before {
    width: 100%
}

.navbar-nav .nav-item:hover .sub-menu {
    top       : 100%;
    opacity   : 1;
    visibility: visible
}

.navbar-nav .nav-item .sub-menu {
    width             : 200px;
    background-color  : #fff;
    box-shadow        : 0 0 20px 0 rgba(0, 0, 0, .1);
    position          : absolute;
    top               : 110%;
    left              : 0;
    opacity           : 0;
    visibility        : hidden;
    transition        : all .3s ease-out 0s
}

.navbar-nav .nav-item .sub-menu li {
    display: block
}

.navbar-nav .nav-item .sub-menu li a {
    display: block;
    padding: 8px 20px;
    color  : #222
}

.navbar-nav .nav-item .sub-menu li a.active,
.navbar-nav .nav-item .sub-menu li a:hover {
    padding-left: 25px;
    color       : #4caf50
}

.navbar-nav .sub-nav-toggler {
    display: none
}

.navbar-nav .sub-nav-toggler span {
    width            : 8px;
    height           : 8px;
    border-left      : 1px solid #222;
    border-bottom    : 1px solid #222;
    transform        : rotate(-45deg);
    position         : relative;
    top              : -5px
}


.sticky {
    position          : fixed;
    z-index           : 100;
    background-color  : #00000085;
    box-shadow        : 0 20px 50px 0 rgba(0, 0, 0, .05);
    transition        : all .3s ease-out 0s
}

.navbar {
    padding: 15px 20px
}

.navbar-toggler .icon-bar {
    background-color: #ffffffcf
}

/************************************ */
.white-theme {
    background-color  : #fff;
}

.white-theme .navbar-collapse {
    background-color: #ffffffcf;
}

.white-theme .navbar-toggler .icon-bar {
    background-color: #222
}

.white-theme .navbar-nav .nav-item span {
    color: #222
}

.white-theme .navbar-nav .nav-item a {
    color: #222
}

.white-theme .navbar-nav .nav-item a::before {
    background-color: #4caf50
}

.white-theme .navbar-nav .nav-item a.active,
.white-theme .navbar-nav .nav-item a:hover {
    color: #4caf50
}
/************************************ */

</style>

<style scoped>

  .dropdown-enter-active,
  .dropdown-leave-active {
    transition: all .3s ease-in-out;
  }

  .dropdown-enter-from,
  .dropdown-leave-to {
    opacity: 0;
    transform: translateY(-12px);
  }

</style>
