<template>
   <div>
      <div id="div-wanna-sell" :class="[{'parallax': !isMobile}, 'custom-bg']">
         <div class="content">
            <h2 class="text-uppercase" data-aos="zoom-in" data-aos-once="true" data-aos-duration="700" data-aos-delay="0" data-aos-anchor="#div-wanna-sell" data-aos-anchor-placement="center-bottom">{{ $t('sell_us_your_fruit_section.banner.title') }}</h2>
            <router-link to="/wanna-sell-your-fruit">
               <button class="btn btn-more-info btn-success" data-aos="fade-up" data-aos-once="true" data-aos-duration="700" data-aos-delay="800" data-aos-anchor="#div-wanna-sell" data-aos-anchor-placement="center-bottom">{{ $t('sell_us_your_fruit_section.banner.button_text') }} &nbsp;<font-awesome-icon :icon="['fas', 'money-check-alt']" /></button>
            </router-link>
         </div>
      </div>
   </div>
</template>

<script>

export default {

   name: 'WannaSellYourFruitBanner',
   props: {
      isMobile: {
         type: Boolean,
         default: () => true,
         required: false
      }
   }

}

</script>

<style lang="scss" scoped>

@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);

.custom-bg {

   $paddingY: 6.25rem;

   width                   : 100%;
   background-image        : url('./images/avocado in hand.png');
   background-size         : cover;
   background-repeat       : no-repeat;
   background-position     : top center;
   background-color        : #0000005c;
   background-blend-mode   : multiply;
   padding-top             : $paddingY;
   padding-bottom          : $paddingY;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      padding-top             : $paddingY * 1.2;
      padding-bottom          : $paddingY * 1.2;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      padding-top             : $paddingY * 1.4;
      padding-bottom          : $paddingY * 1.4;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      padding-top             : $paddingY * 1.5;
      padding-bottom          : $paddingY * 1.5;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      padding-top             : $paddingY * 1.2;
      padding-bottom          : $paddingY * 1.2;
   }

}

.parallax {
   overflow                : hidden;
   position                : relative;
   background-attachment   : fixed;
}

.content {

   text-align: center;
   
   & h2 {

      $fontSize: 1.57rem;
      $textShadow: 0.93px;
      $letterSpacing: 0.75px;

      text-transform: uppercase;
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
      color: white;
      letter-spacing: $letterSpacing;
      font-size: $fontSize;
      text-shadow: $textShadow $textShadow $textShadow #000;

      /* Small devices (landscape phones, 576px and up) */
      @media (min-width: 576px) {
         font-size: $fontSize * 1.2;
         text-shadow: ($textShadow * 1.2) ($textShadow * 1.2) ($textShadow * 1.2) #000;
         letter-spacing: $letterSpacing * 1.2;
      }

      /* Medium devices (tablets, 768px and up) */
      @media (min-width: 768px) {
         font-size: $fontSize * 1.6;
         text-shadow: ($textShadow * 1.6) ($textShadow * 1.6) ($textShadow * 1.6) #000;
         letter-spacing: $letterSpacing * 1.6;
      }

      /* Large devices (desktops, 992px and up) */
      @media (min-width: 992px) {
         font-size: $fontSize * 2.0;
         text-shadow: ($textShadow * 2.0) ($textShadow * 2.0) ($textShadow * 2.0) #000;
         letter-spacing: $letterSpacing * 2.0;
      }

      /* Extra large devices (large desktops, 1200px and up) */
      @media (min-width: 1200px) {
         font-size: $fontSize * 1.8;
         text-shadow: ($textShadow * 1.8) ($textShadow * 1.8) ($textShadow * 1.8) #000;
         letter-spacing: $letterSpacing * 1.8;
      }      

   }
   
   & .btn-more-info {

      $basePaddingY: 0.21rem;
      $basePaddingX: 0.42rem;
      $baseFontSize: 0.74rem;
      $baseBorderRadius: 0.17rem;
      $marginTop: 1rem;

      padding: $basePaddingY $basePaddingX;
      font-size: $baseFontSize;
      border-radius: $baseBorderRadius;
      margin-top: $marginTop;

      /* Small devices (landscape phones, 576px and up) */
      @media (min-width: 576px) {
         padding: ($basePaddingY * 1.1) ($basePaddingX * 1.1);
         font-size: $baseFontSize * 1.1;
         border-radius: $baseBorderRadius * 1.1;
         margin-top: $marginTop * 1.1;
      }

      /* Medium devices (tablets, 768px and up) */
      @media (min-width: 768px) {
         padding: ($basePaddingY * 1.2) ($basePaddingX * 1.2);
         font-size: $baseFontSize * 1.2;
         border-radius: $baseBorderRadius * 1.2;
         margin-top: $marginTop * 1.2;
      }

      /* Large devices (desktops, 992px and up) */
      @media (min-width: 992px) {
         padding: ($basePaddingY * 1.5) ($basePaddingX * 1.5);
         font-size: $baseFontSize * 1.5;
         border-radius: $baseBorderRadius * 1.5;
         margin-top: $marginTop * 1.5;
      }

      /* Extra large devices (large desktops, 1200px and up) */
      @media (min-width: 1200px) {
         padding: ($basePaddingY * 1.5) ($basePaddingX * 1.5);
         font-size: $baseFontSize * 1.5;
         border-radius: $baseBorderRadius * 1.5;
         margin-top: $marginTop * 1.5;
      }
   }
}

</style>


