<template>

    <div>

        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <!-- <vue3-video-player :core="HLSCore" src="https://aztecavo.com.mx/Aztecavo Esp_4.mp4" title="Aztecavo"></vue3-video-player> -->
                <div class="col-8">
                    <vue3-video-player @global-auto-play="autoPlay" 
                    :src="$t('gallery_section.presentation_video.src')"  
                    :title="$t('gallery_section.presentation_video.title')" 
                    :cover="`${baseUrl}/presentation-video-thumbnail.jpg`"/>
                </div>
            </div>
        </div>

        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style lang="scss" scoped>

//

</style>

<script>

import      HLSCore         from    '@cloudgeek/playcore-hls';
import      Navbar          from    '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer          from    '@/components/Global/FooterComponent/FooterComponent.vue'

const i18nMetaPrefix = 'gallery_section.presentation_video.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'PresentationVideo',
    components: {
        Navbar,
        Footer,
    },
    metaInfo () { 
        return {
            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
                lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
                { name: 'robots', content: 'index, follow'},
                { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
                { property: 'og:title', content: this.$t(i18nPageTitleKey)},
                { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
                { name: 'author', content: 'uligibson@hotmail.com'},
                { property: 'og:type', content: 'website'},
                { property: 'og:url', content: 'https://aztecavo.com.mx/aztecavo-video'},
                { property: 'og:image', content: 'https://aztecavo.com.mx/aztecavo-video-thumbnail.jpg'},
            ],
        }
    },
    setup() {
        //
    },
    data() {
        return {
            sectionLinksData: [
               { isActive: true,  href: '',           i18nKey: 'navbar.presentation_video' },
            ],
            baseUrl: this.baseUrl,
        }
    },
    watch: {
        //
    },
    mounted() {
        // window.scrollTo(0, 0)
    }
}

</script>