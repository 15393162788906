<template>

    <div>
        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" :activeSection="activeSection" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">
                    <h1 class="main-title text-center text-uppercase">{{ $t('cookies_section.cookies_policy') }}</h1>
                </div>
            </div>
            <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
                <div class="col-12">
                    <p class="fw-bold fst-italic">{{ $t('cookies_section.p_1', ['04/02/2022']) }}</p>
                    <p>{{ $t('cookies_section.p_2') }}</p>
                    <p>{{ $t('cookies_section.p_3') }}</p>
                    <p>{{ $t('cookies_section.p_4') }}</p>
                    <h2 class="fw-bold tw-mb-2">{{ $t('cookies_section.h2_1') }}</h2>
                        <p>{{ $t('cookies_section.p_5') }}</p>
                    <h2 class="fw-bold tw-mb-2">{{ $t('cookies_section.h2_2') }}</h2>
                        <p>{{ $t('cookies_section.p_6') }}</p>
                    <h2 class="fw-bold tw-mb-2">{{ $t('cookies_section.h2_3') }}</h2>
                        <p>{{ $t('cookies_section.p_7') }}</p>
                        <p>{{ $t('cookies_section.p_8') }}</p>
                </div>
            </div>
        </div>

        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style scoped>
p {
    margin-bottom: 1.3rem;
}
</style>

<script>

import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'

const i18nMetaPrefix = 'cookies_section.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

export default {
    name: 'CookiesPolicy',
    components: {
        Navbar,
        Footer,
    },
    metaInfo () { 
        return {
            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
                lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
                { name: 'robots', content: 'index, follow'},
                { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
                { property: 'og:title', content: this.$t(i18nPageTitleKey)},
                { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
                { name: 'author', content: 'uligibson@hotmail.com'},
                { property: 'og:type', content: 'website'},
                { property: 'og:url', content: 'https://aztecavo.com.mx/cookies-policy'},
                { property: 'og:image', content: 'https://aztecavo.com.mx/aztecavo.png'},
            ],
        }
    },
    setup() {
        //
    },
    data() {
        return {
            sectionLinksData: [
               { isActive: true,  href: '',           i18nKey: 'navbar.cookies_policy' },
            ],
            navbarwhiteThemeStarts: null,
            activeSection: null,
        }
    },
    methods: {
        //
    },
    watch: {
        //
    },
    computed: {
        //
    },
    mounted() {
        window.scrollTo(0,0)
    }
}

</script>