<template>
   <div>
      
      <metainfo>
         <template v-slot:title="{ content }">{{ content ? `Aztecavo - ${content}` : `Aztecavo` }}</template>
      </metainfo>

      <router-view v-slot="{ Component }">
         <transition name="fade" mode="out-in">
            <component :is="Component" />
         </transition>
      </router-view>

      <CookiesAlert/>

   </div>
</template>

<script>

import      CookiesAlert      from   '@/components/Global/CookiesAlertComponent/CookiesAlertComponent.vue'

const i18nMetaPrefix = 'main_page_section.meta_info'

export default {

   name: 'App',
   components: {
      CookiesAlert
   },
   metaInfo () { 
      return {
         title: "",
         htmlAttrs: { 
            lang: [this.$i18n.locale] 
         },
         description: this.$t(`${i18nMetaPrefix}.description`),
         meta: [
            { name: 'robots', content: 'index, follow'},
            { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
            { property: 'og:title', content: 'Aztecavo'},
            { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
            { name: 'author', content: 'uligibson@hotmail.com'},
            { property: 'og:type', content: 'website'},
            { property: 'og:url', content: 'https://aztecavo.com.mx'},
            { property: 'og:image', content: 'https://aztecavo.com.mx/aztecavo.png'},
         ],
         // script: [
            // { src: 'body-script2.js', to: 'body' }, // inside body tag
            // { src: 'body-script3.js', to: '#body-prepend' }, // before body tag
         // ]
      }
   },
   setup() {
      //
   },

}

</script>

<style src="the-new-css-reset/css/reset.css"></style>
<style src="bootstrap/dist/css/bootstrap.min.css"></style>
<style src="@/assets/css/tailwind.css"></style>
<style src="aos/dist/aos.css"></style>
<style src="sweetalert2/dist/sweetalert2.min.css"></style>

<style>

   .fade-enter-from,
   .fade-leave-to {
      opacity: 0;
   }

   .fade-enter-active,
   .fade-leave-active {
      transition: opacity .5s ease-out;
   }

</style>

<style lang="scss">

@import "https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@300;400;700&display=swap";
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800";

::selection {
  color: #fff;
  background: #4caf50;
}

:root {
   scroll-behavior: initial;
}

html, body {
   scroll-behavior: initial;
}

body {
   font-family: poppins, sans-serif;
   font-weight: 400;
   font-style : normal;
   background-color: #fff;
   color     : #5a5a5a;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
   margin: 0;
   font-weight: 500;
   line-height: 1.2;
}

a {
   display        : inline-block;
   text-decoration: none;

   &:hover {
      cursor: pointer;
   }
}

p {
   font-size  : 16px;
   font-weight: 400;
   line-height: 24px;
   margin     : 0;
}

p.content-text {

   $baseFontSize: 1rem;
   $baseLineH: 1.5rem;

   font-size: $baseFontSize;
   line-height: $baseLineH;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      font-size: $baseFontSize * 1.1;
      line-height: $baseLineH * 1.1;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      font-size: $baseFontSize * 1.1;
      line-height: $baseLineH * 1.1;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      font-size: $baseFontSize * 1.15;
      line-height: $baseLineH * 1.15;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      font-size: $baseFontSize * 1.15;
      line-height: $baseLineH * 1.15;
   }
}

h1.main-title {

   $baseFontSize: 1.8rem;

   color: #2B2A3D;
   font-family: poppins, sans-serif;
   font-weight: 700;
   font-size: $baseFontSize;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      font-size: $baseFontSize * 1.4;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      font-size: $baseFontSize * 1.6;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      font-size: $baseFontSize * 1.8;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      font-size: $baseFontSize * 1.9;
   }

}

h2.section-title {

   $baseFontSize: 1.6rem;

   color: #2B2A3D;
   font-family: poppins, sans-serif;
   font-weight: 700;
   font-size: $baseFontSize;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      font-size: $baseFontSize * 1.4;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      font-size: $baseFontSize * 1.6;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      font-size: $baseFontSize * 1.8;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      font-size: $baseFontSize * 1.9;
   }

}

h3.section-subtitle {

   $baseFontSize: 1.2rem;

   color: #2B2A3D;
   font-family: poppins, sans-serif;
   font-weight: 700;
   font-size: $baseFontSize;

   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      font-size: $baseFontSize * 1.4;
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      font-size: $baseFontSize * 1.6;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      font-size: $baseFontSize * 1.8;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      font-size: $baseFontSize * 1.9;
   }

}

.scroll-snap-y-proximity {
   scroll-snap-type: y proximity;
   scroll-snap-stop: always;
}

</style>
