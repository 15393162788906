<template>

    <div class="">

        <Navbar :backArrow="true" :sectionLinksData="sectionLinksData" :stickyStarts="0" whiteThemeStarts="always" />
        <div class="container tw-mt-28">
            <div class="[ tw-pb-6 ] [ sm:tw-pb-6 ] [ lg:tw-pb-6 ] row justify-content-center">
                <div class="col-12">
                    <h1 class="main-title text-center text-uppercase">{{ $t('wanna_work_with_us_section.title') }}</h1>
                </div>
            </div>
        </div>       
        <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] container">
            <div class="row justify-content-around">
                <div class="[ col-12 ] [ col-sm-12 ] [ col-md-5 ] [ col-lg-5 ] [ col-xl-5 ]">
                    <h2 class="text-center tw-mb-8">{{ $t('wanna_work_with_us_section.leave_us_your_info') }}</h2>
                    <form accept-charset="UTF-8" method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit($event)" class="contact1-form validate-form mx-auto wanna-work-with-us-form">

                        <div class="wrap-input1">
                            <input v-model="v$.fullName.$model" :class="{'invalid': (v$.fullName.$errors.length > 0)}" class="input1" type="text" name="name" autocomplete="name" :placeholder="$t('wanna_work_with_us_section.form.fullname')">
                            <div class="invalid-message" v-for="(error, index) of v$.fullName.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <input v-model="v$.email.$model" :class="{'invalid': (v$.email.$errors.length > 0)}" class="input1" type="text" name="email" autocomplete="email" :placeholder="$t('wanna_work_with_us_section.form.email')">
                            <div class="invalid-message" v-for="(error, index) of v$.email.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <input v-model="v$.departmentOfInterest.$model" :class="{'invalid': (v$.departmentOfInterest.$errors.length > 0)}" class="input1" type="text" name="departmentOfInterest" autocomplete="off" :placeholder="$t('wanna_work_with_us_section.form.area')">
                            <div class="invalid-message" v-for="(error, index) of v$.departmentOfInterest.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <input v-model="v$.phone.$model" :class="{'invalid': (v$.phone.$errors.length > 0)}" class="input1" type="text" name="phone" autocomplete="tel" :placeholder="$t('wanna_work_with_us_section.form.phone_number')">
                            <div class="invalid-message" v-for="(error, index) of v$.phone.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="wrap-input1">
                            <button type="button" @click="cvPrompt" :class="(this.cv.length == 0) ? 'btn-blue' : 'btn-green'" class="btn-round w-100">
                                <span v-if="(this.cv.length == 0)">{{ $t('wanna_work_with_us_section.form.attach_cv') }} &nbsp;<i><font-awesome-icon size="sm" class="" :icon="['fas', 'file-alt']" /></i></span>
                                <span v-else>{{ $t('wanna_work_with_us_section.form.cv_attached') }} &nbsp;<i><font-awesome-icon size="sm" class="" :icon="['fas', 'check-circle']" /></i></span>
                            </button>
                            <input ref="cv" type="file" class="d-none" @change="cvFileChanged($event)" accept="application/pdf">
                            <div class="invalid-message" v-for="(error, index) of v$.cv.$errors" :key="index">
                                {{ error.$message }}
                            </div>
                        </div>

                        <GoogleRecaptcha :resetTrigger="captcha.triggers.reset" :language="this.$i18n.locale" @onCaptchaSuccess="onCaptchaSuccess" @onCaptchaExpired="onCaptchaExpired" @onCaptchaError="onCaptchaError"></GoogleRecaptcha>
                        <div class="invalid-message" v-for="(error, index) of v$.captcha.token.$errors" :key="index">
                            {{ error.$message }}
                        </div>

                        <div class="container-btn-round tw-mt-8">
                            <button type="submit" class="btn-round btn-green btn-icon-anim">
                                <span>{{ $t('wanna_work_with_us_section.form.submit') }} &nbsp;<i><font-awesome-icon size="sm" class="" :icon="['fas', 'long-arrow-alt-right']" /></i></span>
                            </button>
                            <div v-if="(v$.$errors.length > 0)" class="invalid-message mt-3">
                                {{ this.$t('validations.complete_all_fields') }}
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        <Footer :aosAnimations="false" class="[ tw-pt-8 ] [ sm:xtw-pb-7 ] [ md:xtw-pb-8 ] [ lg:xtw-pb-12 ]" />

    </div>

</template>

<style lang="scss" scoped>

@font-face {
    font-family: Montserrat-Bold;
    src        : url(fonts/montserrat/Montserrat-Bold.ttf)
}

@font-face {
    font-family: Montserrat-ExtraBold;
    src        : url(fonts/montserrat/Montserrat-ExtraBold.ttf)
}

@font-face {
    font-family: Montserrat-Medium;
    src        : url(fonts/montserrat/Montserrat-Medium.ttf)
}

.contact1 {
    width          : 100%;
    min-height     : 100%;
    padding        : 15px;
    background     : #fff;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
    align-items    : center
}

.container-contact1 {
    width          : 1163px;
    background     : #fff;
    border-radius  : 10px;
    overflow       : hidden;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-between;
    align-items    : center;
    padding        : 0 185px 0 170px
}

.contact1-form {
    width: 390px
}

.input1::placeholder {
    color: #999
}

textarea.input1 {
    min-height   : 90px;
    border-radius: 25px;
    padding      : 12px 30px
}

textarea.input1::placeholder {
    color: #999
}

.wrap-input1 {
    position     : relative;
    width        : 100%;
    z-index      : 1;
    margin-bottom: 20px
}

.input1 {
    height       : 50px;
    border-radius: 25px;
    padding      : 0 22px;
    display    : block;
    width      : 100%;
    background-color : #efefef;
    font-family: Montserrat-Bold;
    font-size  : 15px;
    line-height: 1.5;
    color      : #666;
    border: 1px solid #66666659;
    transition: border .3s, background-color .3s, box-shadow .3s;

    &.invalid {
        border: 2px solid #dc3545;
        padding-right: 3em;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: right 1.1em center;
        background-size: 1.425rem 1.425rem;

        &:focus {
            background-color: #f9f9f9;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.2);
            border-color: rgba(255, 0, 0, 0.2);
        }

        &~.invalid-message {
            display: block;
        }
    }

    &:focus {
        background-color: #f9f9f9;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(157 157 157 / 51%);
        border-color: rgb(157 157 157 / 51%);
    }
}

.invalid-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
    padding-left: 24px;
}

.container-btn-round {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center
}

.btn-round {
    min-width         : 193px;
    height            : 50px;
    border-radius     : 25px;
    font-family       : Montserrat-Bold;
    font-size         : 15px;
    line-height       : 1.5;
    color             : #fff;
    display           : flex;
    justify-content   : center;
    align-items       : center;
    padding           : 0 25px;
    transition        : all .4s;

    &:disabled {
        cursor: default;
        // background-color: #777;
        opacity: .65;
    }

    & i {
        display: inline-block
    }
}

.btn-icon-anim {

    & i {
        // margin-left       : 7px;
        transition        : transform .4s
    }

    &:hover:not([disabled]) > span > i {
        transform: translateX(10px)
    }
}

.btn-green {
    background        : #198754;

    &:hover:not([disabled]) {
        background: #157347
    }
}

.btn-blue {
    background        : #3490DC;

    &:hover:not([disabled]) {
        background: #227DC7
    }
}

@media (max-width:1200px) {
    .contact1-form {
        //
    }
}

@media (max-width:992px) {
    .container-contact1 {
        padding: 90px 0 0 90px
    }

    .contact1-form {
        width: 55%
    }
}

@media (max-width:768px) {
    .container-contact1 {
        padding: 0 80px 0 80px
    }

    .contact1-form {
        width: 100%
    }
}

@media (max-width:576px) {
    .container-contact1 {
        padding: 0 15px 0 15px
    }
}
</style>

<script>

import useVuelidate from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import { required, email } from '@/utils/i18n-validators'
import { sleep } from '@/utils/shane-utils.js';
import apiClient from '@/services/api.js';

import      Navbar            from   '@/components/Home/NavbarComponent/NavbarComponent.vue'
import      Footer            from   '@/components/Global/FooterComponent/FooterComponent.vue'
import      GoogleRecaptcha   from   '@/components/Global/GoogleRecaptchaComponent/GoogleRecaptchaComponent.vue'

const i18nMetaPrefix = 'wanna_work_with_us_section.meta_info'
const i18nPageTitleKey = `${i18nMetaPrefix}.page_title`

const file_size_validation = (file) =>  {
    if (!file) {
        return true;
    }
    return (file.size < 10 * (1024 * 1024)) // 10MB
}

export default {
    name: 'WannaWorkWithUs',
    components: {
        Navbar,
        Footer,
        GoogleRecaptcha,
    },
    metaInfo () { 
        return {
            title: this.$t(i18nPageTitleKey),
            htmlAttrs: { 
               lang: [this.$i18n.locale] 
            },
            description: this.$t(`${i18nMetaPrefix}.description`),
            meta: [
               { name: 'robots', content: 'index, follow'},
               { name: 'keywords', content: this.$t(`${i18nMetaPrefix}.keywords`)},
               { property: 'og:title', content: this.$t(i18nPageTitleKey)},
               { property: 'og:description', content: this.$t(`${i18nMetaPrefix}.description`)},
               { name: 'author', content: 'uligibson@hotmail.com'},
               { property: 'og:type', content: 'website'},
               { property: 'og:url', content: 'https://aztecavo.com.mx/wanna-work-with-us'},
               { property: 'og:image', content: 'https://aztecavo.com.mx/bolsa-de-trabajo-aztecavo.png'},
            ],
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            captcha: {
                token: '',
                triggers: {
                    reset: 0
                },
            },
            fullName: '',
            email: '',
            departmentOfInterest: '',
            phone: '',
            cv: '',
            sectionLinksData: [
                { isActive: true,  href: '',           i18nKey: 'navbar.join_our_team' },
            ],
        }
    },
    validations () {
        return {
            fullName: { required },
            email: { required, email },
            departmentOfInterest: { required },
            phone: { required },
            cv: { 
                required: helpers.withMessage(() => this.$t('validations.cv_required'), required),
                file_size_validation: helpers.withMessage(() => this.$t('validations.max_file_size', {'maxSize': 10}), file_size_validation),
            },
            captcha: {
                token: {
                    required: helpers.withMessage(() => this.$t('validations.recaptcha'), required)
                }
            }
        }
    },
    methods: {
        onCaptchaSuccess(token) {
            this.captcha.token = token
        },
        onCaptchaExpired() {
            this.captcha.token = ''
        },
        onCaptchaError() {
            this.captcha.token = ''
        },
        onSubmit(event) {

            // this.loading = true; // can use this to triggle a :disabled on login button

            // set all fields to touched
            this.v$.$touch()

            // stop here if form is invalid
            if (this.v$.$invalid) {
                return
            }

            this.$swal({
                icon: 'info',
                // title: ``,
                html: `
                    <div class="h4 mb-3 justify-content-center">${this.$t('swal_general.sending_data_alert.title')}</div>
                    <div class="mb-2 justify-content-center">${this.$t('swal_general.sending_data_alert.message')}</div>
                `,
                // footer: `
                //     <div class="h5 text-success">
                //         <i class="fas fa-search">&nbsp;</i> <span>Text</span>
                //     </div>
                // `,
                // confirmButtonText: '<i class="fas fa-save">&nbsp;</i> Almacenar',
                showConfirmButton: true,
                showCancelButton: false,
                showCloseButton: false,
                showLoaderOnConfirm: true,
                allowEscapeKey: () => !this.$swal.isLoading(),
                allowOutsideClick: () => !this.$swal.isLoading(),
                // customClass: {
                //     confirmButton: 'btn btn-swal2 btn-success'
                // },
                // buttonsStyling: false,
                showClass: {
                    popup: 'animate__animated animate__zoomIn animate__fast'
                },
                hideClass: {
                    popup: 'animate__animated animate__zoomOut animate__fast'
                },
                didOpen: () => {
                    // topModalRemoveTabIndex()
                    this.$swal.getConfirmButton().click()
                },
                didClose: () => {
                    // topModalAddTabIndex()
                },
                preConfirm: () => {

                    console.log('Getting authorization...')

                    return apiClient.get('/sanctum/csrf-cookie').then(response => {
                        console.log('Authorization response:')
                        console.log(response)
                        if(response.status === 204) { // success
                            console.log('Now sending data...');
                            let formData = new FormData();
                            formData.append('captchaToken', this.captcha.token);
                            formData.append('fullName', this.fullName);
                            formData.append('email', this.email);
                            formData.append('departmentOfInterest', this.departmentOfInterest);
                            formData.append('phone', this.phone);
                            formData.append('cv', this.cv);
                            return apiClient.post('/api/wanna-work-with-us', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then((response2) => {
                                console.log('Server response:');
                                console.log(response2);
                                return response2
                            }).catch(error => {
                                console.log(error);
                            })
                        } else {
                            return response
                        }
                    }).catch(error => {
                        console.log(error);
                    })
        
                }
            }).then((response) => {

                if(response.value.data.success) {

                    this.clearForm()

                    sleep(200).then(() => {
                        this.$swal.fire({
                            icon: 'success',
                            html: `
                                <div class="h3 pb-3 fw-bold">${this.$t('wanna_work_with_us_section.swal.data_sent_alert.title')}</div>
                                <div class="text-start mb-2">${this.$t('wanna_work_with_us_section.swal.data_sent_alert.message')}</div>
                            `,
                            confirmButtonText: this.$t('wanna_work_with_us_section.swal.data_sent_alert.confirm_btn_text'),
                            // cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                            showCloseButton: true,
                            allowEscapeKey: true,
                            allowOutsideClick: true,
                            // showLoaderOnConfirm: true,
                            customClass: {
                                confirmButton: 'btn btn-swal2 btn-success',
                                cancelButton: 'btn btn-swal2 btn-grey'
                            },
                            buttonsStyling: false,
                            showClass: {
                                popup: 'animate__animated animate__zoomIn animate__fast'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__zoomOut animate__fast'
                            },
                            didOpen: () => {
                                // topModalRemoveTabIndex()
                                // Swal.getConfirmButton().focus()
                            },
                            didClose: () => {
                                // topModalAddTabIndex()
                            }
                        
                        })
                    })

                } else {

                    this.$swal({
                        icon: 'error',
                        html: `
                            <div class="h2 mb-0 pb-2 font-weight-bold">${this.$t('swal_general.error_alert.title')}</div>
                            <div class="text-start mt-4 mb-4">${this.$t('swal_general.error_alert.message')}</div> 
                            <div class="text-start text-danger mt-4 mb-3"> ${response.value.data.message} </div>
                        `,
                        showConfirmButton: true,
                        confirmButtonText: this.$t('swal_general.error_alert.confirm_btn_text'),
                        showCancelButton: false,
                        showCloseButton: true,
                        customClass: {
                            confirmButton: 'btn btn-swal2 btn-danger',
                            // cancelButton: 'btn btn-swal2 btn-grey'
                        },
                        buttonsStyling: false,
                        showClass: {
                            popup: 'animate__animated animate__zoomIn animate__fast'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__zoomOut animate__fast'
                        },
                        didOpen: () => {
                            // topModalRemoveTabIndex()
                        },
                        didClose: () => {
                            // topModalAddTabIndex()
                        }
                    })
                }
            
            })
        },
        cvPrompt() {
            this.$refs.cv.click()
        },
        cvFileChanged(event) {
            const files = event.target.files || event.dataTransfer.files;
            if(files.length > 0) {
                this.cv = files[0]
            } else {
                this.cv = ''
                this.$refs.cv.value = ''
            }
            this.v$.cv.$touch()
        },
        clearForm() {
            // reset form validation errors
            this.v$.$reset()

            this.captcha.triggers.reset++
            this.captcha.token = ''
            this.fullName = ''
            this.email = ''
            this.departmentOfInterest = ''
            this.phone = ''
            this.cv = ''
            this.$refs.cv.value = ''
            
        }
    },
    watch: {
        //
    },
    mounted() {
        // window.scrollTo(0, 0)
    }
}

</script>
