import { createApp } from 'vue'
import App from '@/modules/App/App.vue';
import routes from '@/routes.js';
import store from "@/store.js";
import 'bootstrap';
import i18n from '@/i18n.js';
// import { createMetaManager, defaultConfig, deepestResolver } from 'vue-meta'
import { createMetaManager, defaultConfig, plugin as vueMetaPlugin } from 'vue-meta'
const metaManager = createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
});
import VueClickAway from 'vue3-click-away'
// @ts-ignore
import AOS from 'aos'
import VueSmoothScroll from 'vue3-smooth-scroll'
// @ts-ignore
import VuePictureSwipe from 'vue3-picture-swipe'
import 'animate.css';
import VueSweetalert2 from 'vue-sweetalert2';
const swalOptions = {
    confirmButtonColor: '#198754',
    cancelButtonColor: '#6C757D',
}
import VueGtag from "vue-gtag"
import axios from 'axios'
import VueAxios from 'vue-axios'
// @ts-ignore
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
// - FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
// ---- Brands
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// ---- Solid
import { faEnvelope, faPhone, faMapMarkerAlt, faLink, faChevronCircleRight, faLongArrowAltRight, faStore, faBriefcase, faMoneyCheckAlt, faFileAlt, faCheckCircle, faArrowCircleLeft, faCity, faBuilding, faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faEnvelope)
library.add(faPhone)
library.add(faMapMarkerAlt)
library.add(faLink)
library.add(faChevronCircleRight)
library.add(faLongArrowAltRight)
library.add(faStore)
library.add(faBriefcase)
library.add(faMoneyCheckAlt)
library.add(faWhatsapp)
library.add(faFileAlt)
library.add(faCheckCircle)
library.add(faArrowCircleLeft)
library.add(faDotCircle)
// library.add(

    
export const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon)
.component('vue-picture-swipe', VuePictureSwipe)
.use(i18n)
.use(routes)
.use(store)
.use(vueMetaPlugin, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
})
.use(metaManager)
.use(VueClickAway)
.use(VueSweetalert2, swalOptions)
.use(VueGtag, {
    enabled: process.env.NODE_ENV === 'production',
    config: { 
        id: "G-3Q6WGL4E3D" 
    }
})
.use(VueSmoothScroll, {
    duration: 800,       // animation duration in ms
    offset: 0,           // offset in px from scroll element, can be positive or negative
    // container: '',       // selector string or Element for scroll container, default is window
    updateHistory: false, // whether to push hash to history
    easingFunction: 'easeInOutQuad' // gives the ability to provide a custom easing function `t => ...` (see https://gist.github.com/gre/1650294 for examples) if nothing is given, it will defaults to `easeInOutCubic`
})
.use(Vue3VideoPlayer, {
    lang: 'en'
})
.use(VueAxios, axios)
.provide('axios', app.config.globalProperties.axios)  // provide 'axios'

app.config.globalProperties.basePageTitle = 'Aztecavo'

app.config.globalProperties.publicPath = process.env.BASE_URL

app.config.globalProperties.baseUrl = window.location.origin

// app.AOS = new AOS.init({ disable: "phone" });
// @ts-ignore
app.AOS = new AOS.init();

app.mount('#app');
