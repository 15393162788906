<template>
   <div>
      <div class="container">
         <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
            <div class="col-12">
               <h2 class="section-title text-center text-uppercase">{{ $t('certifications_and_alliances_section.title') }}</h2>
            </div>
         </div>
         <div class="[ tw-pb-10 ] [ sm:tw-pb-10 ] [ lg:tw-pb-10 ] row justify-content-center">
            <div class="col-12">
               <p id="certifications-description" class="[ text-start ] [ text-sm-center mx-md-auto ] content-text">
                  {{ $t('certifications_and_alliances_section.description') }}
               </p>
            </div>
         </div>
      </div>
      <!-- ------------------------------- -->
      <div class="container">
         <div class="[ tw-pb-4 ] row justify-content-center">
            <div class="col-12">
               <h3 class="section-subtitle text-center text-uppercase">{{ $t('certifications_and_alliances_section.certifications.title') }}</h3>
            </div>
         </div>
      </div>
      <div class="[ tw-pb-10 ]">

         <Carousel id="certifications-carousel" :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="(elem, index) in elements" :key="`elem-${index}`">
               <div class="carousel__item">
                  <img :src="elem.src" alt="certifications">
               </div>
            </Slide>

            <template #addons>
               <Pagination />
            </template>
         </Carousel>

      </div>
      <!-- ------------------------------- -->
      <div class="container">
         <div class="[ tw-pb-4 ] row justify-content-center">
            <div class="col-12">
               <h3 class="section-subtitle text-center text-uppercase">{{ $t('certifications_and_alliances_section.alliances.title') }}</h3>
            </div>
         </div>
      </div>
      <div class="">

         <Carousel id="certifications-carousel" :settings="settings2" :breakpoints="breakpoints2">
            <Slide v-for="(elem, index) in elements2" :key="`elem-${index}`">
               <div class="carousel__item">
                  <img :src="elem.src" alt="certifications">
               </div>
            </Slide>

            <template #addons>
               <Pagination />
            </template>
         </Carousel>

      </div>
      <!-- ------------------------------- -->
   </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Pagination, Slide } from 'vue3-carousel';

export default {
   components: {
      Carousel,
      Slide,
      Pagination,
   },

   data: () => ({
      // carousel elems
      elements: [
         { src: require('./images/sader.jpg') },
         { src: require('./images/primusgfs.jpg') },
         { src: require('./images/bioagricert.png') },
         { src: require('./images/kroger.png') },
         { src: require('./images/dear.jpg') },
         { src: require('./images/smeta.jpg') },
         { src: require('./images/ctpat.png') },
         { src: require('./images/fairtrade.jpg') },
         { src: require('./images/globalgap.jpg') },
      ],
      settings: {
         itemsToShow: 2,
         // breakpoints: 'breakpoints',
         autoplay: 3000,
         wrapAround: true,
         mouseDrag: false,
         touchDrag: false,
         pauseAutoplayOnHover: false,
         snapAlign: 'center',
      },
      breakpoints: {
         // 600px and up
         600: {
         itemsToShow: 3,
         snapAlign: 'center',
         },
         // 700px and up
         768: {
         itemsToShow: 4,
         snapAlign: 'center',
         },
         // 1024 and up
         1024: {
         itemsToShow: 4,
         snapAlign: 'center',
         },
      },
      elements2: [
         { src: require('./images/senasica.jpg') },
         { src: require('./images/usda.jpg') },
         { src: require('./images/apeam.png') },
         { src: require('./images/avocados-from-mexico.jpg') },
      ],
      settings2: {
         itemsToShow: 2,
         breakpoints: 'breakpoints2',
         autoplay: 3000,
         wrapAround: true,
         mouseDrag: false,
         touchDrag: false,
         pauseAutoplayOnHover: false,
         snapAlign: 'center',
      },
      breakpoints2: {
         // 600px and up
         600: {
         itemsToShow: 3,
         snapAlign: 'center',
         },
         // 700px and up
         768: {
         itemsToShow: 4,
         snapAlign: 'center',
         },
         // 1024 and up
         1024: {
         itemsToShow: 4,
         snapAlign: 'center',
         },
      },
   })

}

</script>

<style lang="scss" scoped>

#certifications-description {
   
   $baseInlineSize: 510px;

   overflow-wrap: break-word;
   
   
   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) {
      //
   }

   /* Medium devices (tablets, 768px and up) */
   @media (min-width: 768px) {
      inline-size: $baseInlineSize * 1;
   }

   /* Large devices (desktops, 992px and up) */
   @media (min-width: 992px) {
      inline-size: $baseInlineSize * 1.05;
   }

   /* Extra large devices (large desktops, 1200px and up) */
   @media (min-width: 1200px) {
      inline-size: $baseInlineSize * 1.05;
   }

}

</style>

<style scoped>

.carousel__pagination {
   display: none;
}

.carousel__item {
  min-height: 120px;
  height: 160px;
  width: 100%;
  /* background-color: #ccc; */
  /* border: 1px solid #ccc; */
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__slide > .carousel__item {
  transform: scale(1);
  filter: grayscale(100%);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  filter: grayscale(0%);
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
</style>