import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueRecaptcha = _resolveComponent("VueRecaptcha")!

  return (_openBlock(), _createBlock(_component_VueRecaptcha, {
    sitekey: _ctx.siteKey,
    "load-recaptcha-script": false,
    size: "normal",
    onRender: _ctx.rendered,
    onVerify: _ctx.success,
    onExpired: _ctx.expired,
    onError: _ctx.error,
    ref: "recaptcha"
  }, null, 8, ["sitekey", "onRender", "onVerify", "onExpired", "onError"]))
}